<template>
  <b-form-group :id="language.code" :label="language.code">
    <b-form-input :value="translation.translation" @input="change"/>
  </b-form-group>
</template>

<script>
export default {
  name: 'CategoryTranslationInput',
  props: {
    translation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    language() {
      const languages = this.$store.getters['util/getEnabledLanguages'];
      return languages.find((l) => l.id === this.translation?.languageId);
    },
  },
  methods: {
    change(val) {
      this.$emit('input', {
        ...this.translation,
        translation: val,
      });
    },
  },
};
</script>

<style scoped>

</style>
