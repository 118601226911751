<template>
  <b-card v-if="agent" header-bg-variant="warning" header="Agent">
    <dl class="row mb-0">
      <dt class="col-sm-3">Id</dt>
      <dd class="col-sm-9">{{ agent.id }}</dd>

      <dt class="col-sm-3">Name</dt>
      <dd class="col-sm-9">
        <router-link :to="'/agents/' + agent.id">
          {{ agent.name }}
        </router-link>
      </dd>

      <dt class="col-sm-3">Created at</dt>
      <dd class="col-sm-9">{{ agent.createdAt }}</dd>

      <dt class="col-sm-3">Updated at</dt>
      <dd class="col-sm-9">{{ agent.updatedAt }}</dd>
    </dl>
  </b-card>
</template>

<script>

export default {
  name: 'TaskAgent',
  props: {
    agent: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>

</style>
