<template>
  <div>
    <b-card header-bg-variant="success" header="Emails" body-class="p-0">
      <b-overlay :show="loading">
        <b-table :fields="fields" :items="emails" :busy="loading" class="m-0">
          <template v-slot:cell(messageId)="data">
            <a href="#" @click.prevent="selectItem(data.item)">{{ data.item.messageId }}</a>
          </template>
        </b-table>
      </b-overlay>
    </b-card>

    <b-modal id="raw-data-modal" title="Raw Email Event data" size="lg">
      <vue-json-pretty :data="selected"/>
    </b-modal>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import ApiService from '../../services/ApiService';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'UserEmails',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      emails: [],
      fields: ['messageId', 'type', 'date'],
      selected: null,
      loading: false,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async getEmails() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(`/users/emails?email=${encodeURIComponent(this.user.email)}`);
        this.emails = data.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    selectItem(data) {
      this.selected = data;
      this.$bvModal.show('raw-data-modal');
    },
  },
  mounted() {
    this.getEmails();
  },
};
</script>

<style scoped>
.wide-popover {
  max-width: 400px;
}
</style>
