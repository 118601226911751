<template>
  <standard-wrapper>
    <template slot="title">Newsletters</template>
    <router-view/>
  </standard-wrapper>
</template>

<script>
import StandardWrapper from '../components/wrappers/StandardWrapper.vue';

export default {
  name: 'Newsletter',
  components: {
    StandardWrapper,
  },
};
</script>
