<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav navbar-static-top">
      <li class="nav-item">
        <a class="nav-link" @click.prevent="toggleSidebar">
          <font-awesome-icon icon="bars"/>
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">
          Dashboard
        </router-link>
      </li>
      <li class="nav-item d-inline-block">
        <logout class="nav-link"/>
      </li>
    </ul>

    <!-- SEARCH FORM -->
    <!-- <form class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar" type="search" placeholder="Search"
               aria-label="Search">
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <font-awesome-icon icon="search"/>
          </button>
        </div>
      </div>
    </form> -->
  </nav>
</template>

<script>
import Logout from './Logout.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'TopNavbar',
  components: { Logout },
  methods: {
    toggleSidebar() {
      this.$store.commit('ui/toggleSidebar');
    },
  },
  computed: {
    sidebarCollapsed() {
      return this.$store.state.ui.sidebarCollapsed;
    },
  },
  created() {
    document.body.classList.add('sidebar-mini');
    document.body.classList.add('sidebar-open');
  },
  watch: {
    sidebarCollapsed(n) {
      if (n) {
        document.body.classList.add('sidebar-collapse');
        document.body.classList.remove('sidebar-open');
      } else {
        document.body.classList.remove('sidebar-collapse');
        document.body.classList.add('sidebar-open');
      }
    },
  },
};
</script>

<style scoped>

</style>
