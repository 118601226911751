<template>
  <div class="d-flex align-items-center">
    <span v-if="currency && currency.symbolPosition === 'pre'">
      {{ currency.symbol }}
    </span>
    <span>{{ amount }}</span>
    <span v-if="currency && currency.symbolPosition === 'post'">
      {{ currency.symbol }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Price',
  props: {
    currencyId: {
      type: [String, Number],
      required: true,
    },
    amount: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    currency() {
      const currency = `${this.currencyId}`;
      const currencies = this.$store.getters['util/getCurrenciesById'];

      if (currencies && currencies[currency]) {
        return currencies[currency];
      }
      return null;
    },
  },
};
</script>

<style scoped>

</style>
