<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="Update Testimonial">
        <b-form @submit.prevent="save">
          <image-uploader label="Image" title="Image" :value="testimonial.image"
                          :croppable="false" @input="imageAdded" @remove="imageRemoved"/>

          <b-form-group id="name" label="Name">
            <b-form-input v-model="testimonial.name"/>
          </b-form-group>

          <b-form-group id="title" label="Title">
            <b-form-input v-model="testimonial.title"/>
          </b-form-group>

          <b-form-group id="description" label="Content">
            <b-form-textarea v-model="testimonial.text"/>
          </b-form-group>

          <b-form-group id="type" label="Language">
            <b-form-select v-model="testimonial.languageId" :options="languages"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import ImageUploader from '../../components/form/ImageUploader.vue';

export default {
  name: 'TestimonialsEdit',
  components: {
    ImageUploader,
  },
  data() {
    return {
      testimonial: {
        title: '',
        text: '',
        languageId: null,
        imageId: null,
        image: null,
      },
    };
  },
  methods: {
    async save() {
      try {
        await ApiService.put(`/testimonials/${this.$route.params.testimonialId}`, this.testimonial);
        this.$bvToast.toast('Testimonial successfully saved', {
          title: 'Testimonial saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Testimonial NOT saved', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },
    async getTestimonial() {
      const res = await ApiService.get(`/testimonials/${this.$route.params.testimonialId}`);
      this.testimonial = res.data.data;
    },

    imageAdded(data) {
      this.testimonial.image = data;
      this.testimonial.imageId = data.id;
    },

    imageRemoved() {
      this.testimonial.image = null;
      this.testimonial.imageId = null;
    },
  },
  mounted() {
    this.getTestimonial();
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        value: l.id,
        text: l.name,
      }));
    },
  },
};
</script>

<style scoped>

</style>
