<template>
  <b-list-group flush>
    <subscription-small-item v-for="s in subscriptions" :key="s.id" :subscription="s"/>
  </b-list-group>
</template>

<script>
import SubscriptionSmallItem from './SubscriptionSmallItem.vue';

export default {
  name: 'SubscriptionsSmallList',
  components: { SubscriptionSmallItem },
  props: {
    subscriptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped>

</style>
