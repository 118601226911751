<template>
  <div class="row">
    <div class="col-12">
      <freelancers-search-form @search="search" class="mb-3"/>
      <published-stats v-if="statisticsData" :statistics="statistics"/>
      <b-card header="Freelancers" header-bg-variant="primary">
        <b-table striped :items="items" :fields="fields" id="my-table">
          <template v-slot:cell(id)="data">
            <router-link :to="`/freelancers/${data.item.id}`">{{ data.item.id }}</router-link>
          </template>
          <template v-slot:cell(firstName)="data">
            <router-link :to="`/freelancers/${data.item.id}`">
              {{ data.item.firstName }}
            </router-link>
          </template>
          <template v-slot:cell(lastName)="data">
            <router-link :to="`/freelancers/${data.item.id}`">
              {{ data.item.lastName }}
            </router-link>
          </template>
          <template v-slot:cell(published)="data">
            <status-badge :status="data.item.published"/>
          </template>
          <template v-slot:cell(confirmed)="data">
            <status-badge :status="data.item.confirmed" true-label="Confirmed"
                          false-label="Unconfirmed"/>
          </template>
          <template v-slot:cell(createdAt)="data">
            {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
          </template>
        </b-table>

        <template v-slot:footer class="clearfix">
          <b-pagination-nav
            use-router
            class="m-0 float-right"
            :link-gen="linkGen"
            :number-of-pages="totalPages"
            @change="changePage"
            aria-controls="my-table"/>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import StatusBadge from '../../components/StatusBadge.vue';
import FreelancersSearchForm from '../../components/form/FreelancersSearchForm.vue';
import PublishedStats from '../../components/ui/PublishedStats.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'FreelancersSearch',
  components: {
    PublishedStats,
    FreelancersSearchForm,
    StatusBadge,
  },
  data() {
    return {
      fields: ['id', 'firstName', 'lastName', 'location', 'published', 'confirmed', 'createdAt'],
      perPage: 10,
      currentPage: null,
      rows: 0,
      items: [],
      statisticsData: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.rows / this.perPage) || 1;
    },
    statistics() {
      const languages = this.$store.getters['util/getEnabledLanguagesMap'];
      if (languages) {
        return this.statisticsData.map((s) => ({
          ...s,
          unpublished: s.total - s.published,
          language: languages[s.languageId],
        }));
      }
      return [];
    },
  },
  mounted() {
    this.currentPage = this.$route.query.page || 1;
    this.fetchItems();
    this.getStatistics();
  },
  methods: {
    search() {
      this.fetchItems();
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    fetchItems() {
      const { searchParams } = this.$store.state.freelancers;
      const {
        q,
        published,
        confirmed,
      } = searchParams || {};
      const apiUrl = `/freelancers?page=${this.currentPage}&perPage=${this.perPage}&q=${encodeURIComponent(q)}&published=${published}&confirmed=${confirmed}`;

      return ApiService.get(apiUrl)
        .then((res) => {
          this.rows = res.data.data.total.value;
          // eslint-disable-next-line no-underscore-dangle
          this.items = res.data.data.hits.map((h) => ({ id: h._id, ...h._source }));
        });
    },
    changePage(e) {
      this.currentPage = e;
      this.fetchItems();
    },

    async getStatistics() {
      const res = await ApiService.get('/statistics/freelancers');
      this.statisticsData = res.data.data;
    },
  },
};
</script>
