<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-card header="Payments" header-bg-variant="primary">
          <b-table striped :items="itemsProvider" :fields="fields" :per-page="perPage"
                   :current-page="currentPage" id="my-table">
            <template v-slot:cell(title)="data">
              <router-link :to="`/payments/${data.item.id}`">{{ data.item.title }}</router-link>
            </template>
            <template v-slot:cell(hash)="data">
              <router-link :to="`/payments/${data.item.id}`">{{ data.item.hash }}</router-link>
            </template>
            <template v-slot:cell(createdAt)="data">
              {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
            </template>
            <template v-slot:cell(paymentMethod)="data">
              {{
                paymentMethods[data.item.paymentMethodId]
                  ? paymentMethods[data.item.paymentMethodId].title : ''
              }}
            </template>
            <template v-slot:cell(amount)="data">
              <price :amount="data.item.amount" :currency-id="data.item.currencyId" />
            </template>
            <template v-slot:cell(status)="data">
              <payment-status-badge :status="data.item.status" />
            </template>
          </b-table>

          <template v-slot:footer class="clearfix">
            <b-pagination
              class="m-0 float-right"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"/>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import Price from '../../components/ui/Price.vue';
import PaymentStatusBadge from '../../components/payments/PaymentStatusBadge.vue';

export default {
  name: 'PaymentsList',
  components: { PaymentStatusBadge, Price },
  data() {
    return {
      fields: ['id', 'hash', 'title', 'amount', 'paymentMethod', 'status', 'createdAt'],
      currentPage: 1,
      perPage: 20,
      rows: 0,
    };
  },
  methods: {
    async itemsProvider(ctx) {
      const res = await ApiService.get(`/payments?page=${ctx.currentPage}&perPage=${ctx.perPage}`);
      this.total = res.data.meta.total;
      return res.data.data;
    },
  },
  computed: {
    paymentMethods() {
      return this.$store.getters['util/getPaymentMethodsById'];
    },
  },
};
</script>

<style scoped>

</style>
