<template>
  <div>
    <div class="p-3">
      <div><strong>Company:</strong> {{ experience.company }}</div>
      <div><strong>From:</strong> {{ new Date(experience.from) | dateFormat('DD.MM.YYYY') }}</div>
      <div>
        <strong>To:</strong>
        <template v-if="experience.to">
          {{ new Date(experience.to) | dateFormat('DD.MM.YYYY') }}
        </template>
      </div>
      <div><strong>Title:</strong> {{ experience.title }}</div>
      <p v-html="experience.description"></p>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'FreelancerExperience',
  props: {
    experience: {
      type: Object,
      required: true,
    },
  },
};
</script>
