<template>
  <b-card header-bg-variant="warning" header="Chat" body-class="chat-body">
    <b-list-group v-if="messages.length" flush>
      <message-item v-for="m in messages" :key="m.id" :message="m"/>
    </b-list-group>
    <div class="d-flex justify-content-center p-3">
      <b-button v-if="messages.length" variant="primary" :disabled="loadedAll || loadingMessages"
                @click="loadMessages">Load more
      </b-button>
      <p v-if="!messages.length && !loadingMessages">
        No messages on this application
      </p>
    </div>
  </b-card>
</template>

<script>
import ApiService from '../../services/ApiService';
import MessageItem from '../chat/MessageItem.vue';

export default {
  name: 'ApplicationChat',
  components: { MessageItem },
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingMessages: false,
      messages: [],
      total: 0,
      lastId: null,
    };
  },
  mounted() {
    this.loadMessages();
  },
  methods: {
    async loadMessages() {
      this.loadingMessages = true;
      const res = await ApiService.get(`/messages/${this.application.id}?lastId=${this.lastId || ''}`);
      this.messages.push(...res.data.data.messages);
      this.total = res.data.data.total;
      this.lastId = this.messages.length ? this.messages[this.messages.length - 1].id : null;
      this.loadingMessages = false;
    },
  },
  computed: {
    loadedAll() {
      return this.total <= this.messages.length;
    },
  },
};
</script>

<style scoped>
.chat-body {
  padding: 0;
  max-height: 600px;
  overflow-y: auto;
}
</style>
