<template>
  <router-link :to="`/skills/${skill.id}`">
    {{ translation }}
  </router-link>
</template>

<script>
export default {
  name: 'SkillListTitle',
  props: {
    skill: {
      type: Object,
      required: true,
    },
  },
  computed: {
    translation() {
      const eng = this.$store.state.util.languages.find((l) => l.code === 'en');
      const engTranslation = this.skill.translations.find((t) => t.languageId === eng.id);
      return engTranslation.translation;
    },
  },
};
</script>

<style scoped>

</style>
