<template>
  <standard-wrapper>
    <template slot="title">Newsletter Templates</template>
    <router-view/>
  </standard-wrapper>
</template>

<script>
import StandardWrapper from '../components/wrappers/StandardWrapper.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Templates',
  components: {
    StandardWrapper,
  },
};
</script>
