<template>
  <standard-wrapper>
    <template slot="title">Clients</template>
    <router-view/>
  </standard-wrapper>
</template>

<script>
import StandardWrapper from '../components/wrappers/StandardWrapper.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Clients',
  components: { StandardWrapper },
};
</script>

<style scoped>

</style>
