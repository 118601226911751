import { render, staticRenderFns } from "./Freelancer.vue?vue&type=template&id=7ebbd628&scoped=true&"
import script from "./Freelancer.vue?vue&type=script&lang=js&"
export * from "./Freelancer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ebbd628",
  null
  
)

export default component.exports