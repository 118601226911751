<template>
  <standard-wrapper>
    <template slot="title">Applications</template>
    <router-view/>
  </standard-wrapper>
</template>

<script>
import StandardWrapper from '../components/wrappers/StandardWrapper.vue';

export default {
  name: 'Applications',
  components: { StandardWrapper },
};
</script>

<style scoped>

</style>
