<template>
  <div class="row">
    <div class="col-12">
      <b-card header="Agents" header-bg-variant="primary">
        <b-table striped :items="itemsProvider" :fields="fields" :per-page="perPage"
                 :current-page="currentPage" id="agents-table">
          <template v-slot:cell(id)="data">
            <router-link :to="`/agents/${data.item.id}`">{{ data.item.id }}</router-link>
          </template>
          <template v-slot:cell(name)="data">
            <router-link :to="`/agents/${data.item.id}`">
              {{ data.item.name }}
            </router-link>
          </template>
          <template v-slot:cell(published)="data">
            <status-badge :status="data.item.published"/>
          </template>
          <template v-slot:cell(confirmed)="data">
            <status-badge :status="data.item.confirmed" true-label="Confirmed"
                          false-label="Unconfirmed"/>
          </template>
          <template v-slot:cell(default)="data">
            <status-badge :status="data.item.default" true-label="Yes"
                          false-label="No"/>
          </template>
          <template v-slot:cell(createdAt)="data">
            {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
          </template>
        </b-table>

        <template v-slot:footer class="clearfix">
          <b-pagination
            class="m-0 float-right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="agents-table"/>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import StatusBadge from '../../components/StatusBadge.vue';
import ApiService from '../../services/ApiService';

export default {
  name: 'AgentsList',
  components: { StatusBadge },
  data() {
    return {
      fields: ['id', 'name', 'location', 'published', 'confirmed', 'default', 'createdAt'],
      perPage: 10,
      currentPage: null,
      rows: 0,
      items: [],
    };
  },
  methods: {
    async itemsProvider(ctx) {
      const { data } = await ApiService.get(`/agents?page=${ctx.currentPage}`);
      this.rows = data.meta.total;
      return data.data;
    },
  },
};
</script>

<style scoped>

</style>
