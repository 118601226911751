<template>
  <b-img v-bind="options" :src="src" v-if="hasAvatar" :rounded="rounded" alt="Avatar"/>
</template>

<script>
export default {
  name: 'AvatarImage',
  props: {
    avatar: {
      type: Object,
      default() {
        return {};
      },
    },
    rounded: {
      type: String,
      default: '0',
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      avatarOptions: {
        resize: {
          width: 240,
          height: 240,
        },
      },
    };
  },
  computed: {
    /**
     * Check if avatar exists
     */
    hasAvatar() {
      return !!this.avatar && !!this.avatar.fileName;
    },

    src() {
      const imageRequest = JSON.stringify({
        bucket: process.env.VUE_APP_PUBLIC_BUCKET,
        key: this.avatar.fileName,
        edits: this.avatarOptions,
      });
      return `${process.env.VUE_APP_IMAGE_BASE_URL}/${btoa(imageRequest)}`;
    },
  },
};
</script>

<style scoped>

</style>
