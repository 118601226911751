<template>
  <div>
    <b-button-group class="mb-3">
      <b-button @click="goNext" :disabled="!hasNext">
        <font-awesome-icon icon="arrow-left"/>
      </b-button>
      <b-button @click="goPrevious" :disabled="!hasPrevious">
        <font-awesome-icon icon="arrow-right"/>
      </b-button>
    </b-button-group>
    <div class="row">
      <div class="col-8">
        <b-card no-body>
          <b-card-header header-bg-variant="primary">
            <div class="d-flex align-items-center justify-content-between">
              {{ freelancer.name }}
              <a href="#" @click.prevent="toggleStar" class="text-white">
                <star-toggle :starred="freelancer.adminFlag"/>
              </a>
            </div>
          </b-card-header>
          <b-card-body>
            <b-form @submit.prevent="save">
              <b-form-group id="firstName" label="First Name" class="flex-grow-1">
                <b-input-group>
                  <b-form-input v-model="freelancer.firstName"/>
                  <b-input-group-append>
                    <clean-button v-model="freelancer.firstName"/>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group id="lastName" label="Last Name">
                <b-input-group>
                  <b-form-input v-model="freelancer.lastName"/>
                  <b-input-group-append>
                    <clean-button v-model="freelancer.lastName"/>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group id="occupation" label="Occupation">
                <b-form-input v-model="freelancer.occupation"/>
              </b-form-group>

              <b-form-group id="countryId" label="Country">
                <b-form-select v-model="freelancer.countryId" :options="countries"/>
              </b-form-group>

              <b-form-group id="location" label="Location">
                <b-input-group>
                  <b-form-input v-model="freelancer.location"/>
                  <b-input-group-append>
                    <clean-button v-model="freelancer.location"/>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group id="featured" label="Featured">
                <b-form-checkbox v-model="freelancer.featured"/>
              </b-form-group>

              <b-form-group id="travel" label="Travel">
                <b-form-checkbox v-model="freelancer.travel"/>
              </b-form-group>

              <b-form-group id="resume" label="Resume">
                <wysiwyg-editor v-model="freelancer.resume"/>
              </b-form-group>

              <b-form-group id="bio" label="Bio">
                <wysiwyg-editor v-model="freelancer.bio"/>
              </b-form-group>

              <b-form-group id="linkedin" label="LinkedIn">
                <b-form-input v-model="freelancer.linkedin"/>
              </b-form-group>

              <b-form-group id="web" label="Web address">
                <b-form-input v-model="freelancer.web"/>
              </b-form-group>

              <b-form-group id="blog" label="Blog">
                <b-form-input v-model="freelancer.blog"/>
              </b-form-group>

              <b-form-group id="visibility" label="Visibility">
                <b-form-input v-model="freelancer.visibility"/>
              </b-form-group>

              <b-button type="submit" variant="primary">Save</b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-4">
        <b-card header-bg-variant="info" header="Info">
          <dl class="row mb-0">
            <dt class="col-sm-3">Id</dt>
            <dd class="col-sm-9">{{ freelancer.id }}</dd>

            <dt class="col-sm-3">Created at</dt>
            <dd class="col-sm-9">{{ freelancer.createdAt }}</dd>

            <dt class="col-sm-3">Updated at</dt>
            <dd class="col-sm-9">{{ freelancer.updatedAt }}</dd>

            <dt class="col-sm-3">Published</dt>
            <dd class="col-sm-9">
              <status-badge :status="freelancer.published"/>
            </dd>

            <dt class="col-sm-3">Confirmed</dt>
            <dd class="col-sm-9">
              <status-badge :status="freelancer.confirmed" true-label="Confirmed"
                            false-label="Unconfirmed"/>
            </dd>
          </dl>

          <confirmation-toggle-button :confirmed="freelancer.confirmed" @toggle="freelancerToggle"/>
        </b-card>

        <user-info-card :user="freelancer.user" @toggled="userToggled"/>
        <avatar-display :avatar="freelancer.avatar"/>
        <freelancer-skills :categories="freelancer.categories" :skills="freelancer.skills"
                           :language-id="user.languageId"/>
        <freelancer-experiences :freelancer="freelancer"/>
        <freelancer-projects :freelancer="freelancer"/>
        <freelancer-invite :freelancer="freelancer"/>
        <freelancer-language :freelancer="freelancer"/>
        <freelancer-subscriptions :freelancer-id="$route.params.freelancerId"/>
      </div>
    </div>
  </div>
</template>

<script>
import set from 'lodash/set';
import ApiService from '../../services/ApiService';
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import StatusBadge from '../../components/StatusBadge.vue';
import UserInfoCard from '../../components/user/UserInfoCard.vue';
import AvatarDisplay from '../../components/user/AvatarDisplay.vue';
import FreelancerSkills from '../../components/user/FreelancerSkills.vue';
import FreelancerExperiences from '../../components/user/FreelancerExperiences.vue';
import FreelancerProjects from '../../components/user/FreelancerProjects.vue';
import FreelancerLanguage from '../../components/user/FreelancerLanguage.vue';
import CleanButton from '../../components/form/CleanButton.vue';
import FreelancerSubscriptions from '../../components/user/FreelancerSubscriptions.vue';
import StarToggle from '../../components/ui/StarToggle.vue';
import FreelancerInvite from '../../components/user/FreelancerInvite.vue';
import ConfirmationToggleButton from '../../components/user/ConfirmationToggleButton.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Freelancer',
  components: {
    ConfirmationToggleButton,
    FreelancerInvite,
    StarToggle,
    FreelancerSubscriptions,
    CleanButton,
    FreelancerLanguage,
    FreelancerProjects,
    FreelancerExperiences,
    FreelancerSkills,
    AvatarDisplay,
    UserInfoCard,
    StatusBadge,
    WysiwygEditor,
  },
  data() {
    return {
      freelancer: {},
      meta: {
        previous: null,
        next: null,
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    user() {
      return this.freelancer.user ? this.freelancer.user : {};
    },

    hasNext() {
      return !!this.meta.next;
    },

    hasPrevious() {
      return !!this.meta.previous;
    },

    countries() {
      return this.$store.state.util.countries.map((c) => ({
        value: c.id,
        text: c.name,
      }));
    },
  },
  methods: {
    async fetchData() {
      try {
        const { freelancerId } = this.$route.params;

        const res = await ApiService.get(`/freelancers/${freelancerId}`);
        this.freelancer = res.data.data;
        this.meta = res.data.meta;
      } catch (err) {
        this.$bvToast.toast(err.message, {
          title: 'Error',
          variant: 'danger',
        });
        await this.$router.replace('/freelancers');
      }
    },
    async save() {
      await ApiService.put(`/freelancers/${this.freelancer.id}`, this.freelancer);
      this.$bvToast.toast('Freelancer successfully saved', {
        title: 'Freelancer saved',
        variant: 'success',
      });
    },

    async freelancerToggle() {
      await ApiService.put(`/freelancers/${this.freelancer.id}/toggle`);
      this.freelancer.confirmed = !this.freelancer.confirmed;
      this.$bvToast.toast('Freelancer successfully saved', {
        title: 'Freelancer saved',
        variant: 'success',
      });
    },

    userToggled() {
      set(this, 'freelancer.user.active', !this.freelancer.user.active);
    },

    goPrevious() {
      this.$router.push(`/freelancers/${this.meta.previous}`);
      this.fetchData();
    },

    goNext() {
      this.$router.push(`/freelancers/${this.meta.next}`);
      this.fetchData();
    },

    async toggleStar() {
      this.freelancer.adminFlag = !this.freelancer.adminFlag;
      await this.save();
    },
  },
};
</script>

<style scoped>

</style>
