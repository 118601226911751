<template>
  <div class="row">
    <div class="col-8">
      <b-card header-bg-variant="primary" :header="task.title">
        <b-form @submit.prevent="save">
          <b-form-group id="languageId" label="Language">
            <b-form-select v-model="task.languageId" :options="languages"/>
          </b-form-group>

          <b-form-group id="title" label="Title">
            <b-form-input v-model="task.title"/>
          </b-form-group>

          <b-form-group id="description" label="Description">
            <wysiwyg-editor v-model="task.description"/>
          </b-form-group>

          <b-form-group id="price" label="Price">
            <b-form-input v-model="task.price"/>
          </b-form-group>

          <b-form-group id="currencyId" label="Currency">
            <b-form-select v-model="task.currencyId" :options="currencies"/>
          </b-form-group>

          <b-form-group id="priceType" label="Price Type">
            <b-form-select v-model="task.priceType" :options="priceTypeOptions"/>
          </b-form-group>

          <b-form-group id="negotiablePrice" label="Negotiable price">
            <b-form-checkbox v-model="task.negotiablePrice"/>
          </b-form-group>

          <b-form-group id="duration" label="Duration">
            <b-form-input v-model="task.duration"/>
          </b-form-group>

          <b-form-group id="negotiableDuration" label="Negotiable duration">
            <b-form-checkbox v-model="task.negotiableDuration"/>
          </b-form-group>

          <b-form-group id="type" label="Type">
            <b-form-select v-model="task.type" :options="taskTypes"/>
          </b-form-group>

          <b-form-group id="location" label="Location">
            <b-form-select v-model="task.location" :options="taskLocations"/>
          </b-form-group>

          <b-form-group id="status" label="Status">
            <b-form-select v-model="task.status" :options="taskStatuses"/>
          </b-form-group>

          <b-form-group id="featured" label="Featured">
            <b-form-checkbox v-model="task.featured"/>
          </b-form-group>

          <b-form-group id="visibility" label="Visibility">
            <b-form-input v-model="task.visibility"/>
          </b-form-group>

          <b-form-group id="escrowStatus" label="Escrow Status">
            <b-form-select v-model="task.escrowStatus" :options="escrowStatusesSelect"/>
          </b-form-group>

          <b-form-group id="escrowShowcase" label="Escrow showcase">
            <b-form-checkbox v-model="task.escrowShowcase" :disabled="task.escrowStatus !== 2"/>
          </b-form-group>

          <b-form-group id="agencyOn" label="Agency enabled">
            <b-form-checkbox v-model="task.agencyOn"
                             :disabled="task.type === 'onetime' && task.escrowStatus === 0"/>
          </b-form-group>

          <b-form-group id="confirmed" label="Confirmed">
            <b-form-checkbox v-model="task.confirmed"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
    <div class="col-4">
      <b-card v-if="owner" header-bg-variant="success" header="Created by">
        <dl class="row mb-0">
          <dt class="col-sm-3">ID</dt>
          <dd class="col-sm-9">
            <router-link :to="`/clients/${owner.id}`">
              {{ owner.id }}
            </router-link>
          </dd>

          <dt class="col-sm-3">Name</dt>
          <dd class="col-sm-9">{{ owner.name }}</dd>

          <dt class="col-sm-3">Location</dt>
          <dd class="col-sm-9">{{ owner.location }}</dd>

          <template v-if="ownerUser">
            <dt class="col-sm-3">Affiliate ID</dt>
            <dd class="col-sm-9">{{ ownerUser.referrerId }}</dd>
          </template>
        </dl>
      </b-card>

      <b-card header-bg-variant="info" header="Info">
        <dl class="row mb-0">
          <dt class="col-sm-3">Created at</dt>
          <dd class="col-sm-9">{{ task.createdAt }}</dd>

          <dt class="col-sm-3">Updated at</dt>
          <dd class="col-sm-9">{{ task.updatedAt }}</dd>

          <dt class="col-sm-3">BC ID</dt>
          <dd class="col-sm-9">{{ task.bcId || 'N/A' }}</dd>

          <dt class="col-sm-3">Published</dt>
          <dd class="col-sm-9">{{ task.published }}</dd>
        </dl>

        <b-button variant="danger" v-if="task.published" @click.prevent="unpublish"
                  :disabled="publishAction">
          Unpublish
        </b-button>
        <b-button variant="success"
                  v-if="!task.published"
                  @click.prevent="publish"
                  :disabled="!canBePublished || publishAction">
          Publish
        </b-button>

        <p v-if="!task.published && !canBePublished">
          Client not confirmed so task can not be published.
        </p>
      </b-card>

      <task-skills :task="task" @input-categories="changeCategories" @input-skills="changeSkills"/>

      <b-card header-bg-variant="success" header="Escrow">
        <dl class="row mb-0">
          <dt class="col-sm-3">Status</dt>
          <dd class="col-sm-9">
            <escrow-status-badge :task="task" />
          </dd>

          <dt class="col-sm-3">Currency</dt>
          <dd class="col-sm-9">{{ task.cryptoEscrowName }}</dd>

          <dt class="col-sm-3">Public</dt>
          <dd class="col-sm-9">{{ task.escrowPublic }}</dd>

          <dt class="col-sm-3">Balance</dt>
          <dd class="col-sm-9">{{ escrowBalance }}</dd>

          <dt class="col-sm-3">Fee</dt>
          <dd class="col-sm-9">{{ task.escrowBalanceFee }}</dd>

          <dt class="col-sm-3">Balance Without fee</dt>
          <dd class="col-sm-9">{{ task.escrowBalanceWithoutFee }}</dd>

          <div class="d-flex align-items-center">
            <b-button variant="success" @click="refreshBalance" class="mr-2"
                      :disabled="refreshingBalance">
              Refresh balance
            </b-button>
          </div>
        </dl>
      </b-card>

      <task-agent v-if="task" :agent="agent"/>
      <task-subscriptions :task-id="$route.params.taskId"/>
      <task-applications :applications="applications"/>
    </div>
  </div>
</template>

<script>
import TaskSkills from '../../components/task/TaskSkills.vue';
import TaskDetails from '../../mixins/TaskDetails';
import ApiService from '../../services/ApiService';
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import TaskSubscriptions from '../../components/task/TaskSubscriptions.vue';
import TaskApplications from '../../components/task/TaskApplications.vue';
import TaskAgent from '../../components/task/TaskAgent.vue';
import EscrowStatusBadge from '../../components/task/EscrowStatusBadge.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Task',
  components: {
    EscrowStatusBadge,
    TaskAgent,
    TaskApplications,
    TaskSubscriptions,
    TaskSkills,
    WysiwygEditor,
  },
  mixins: [TaskDetails],
  data() {
    return {
      task: {},
      refreshingBalance: false,
      publishAction: false,
      applications: [],
      agent: null,
      ownerUser: null,
    };
  },
  async mounted() {
    const { taskId } = this.$route.params;

    const taskRes = await ApiService.get(`/tasks/${taskId}`);
    this.task = taskRes.data.data;

    const appsRes = await ApiService.get(`/tasks/${taskId}/applications`);
    this.applications = appsRes.data.data;

    await this.getAgent();

    const ownerUser = await ApiService.get(`/users/${this.task.owner.userId}`);
    this.ownerUser = ownerUser.data.data;
  },
  computed: {
    owner() {
      return this.task?.owner;
    },

    canBePublished() {
      return this.owner?.confirmed;
    },

    escrowBalance() {
      return this.task?.escrowBalance?.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    },
  },
  methods: {
    async save() {
      try {
        await ApiService.put(`/tasks/${this.task.id}`, this.task);
        this.$bvToast.toast('Task successfully saved', {
          title: 'Task saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Task NOT saved. Check console for more details.', {
          title: 'Task NOT saved',
          variant: 'danger',
        });
      }
    },

    async unpublish() {
      this.publishAction = true;
      await ApiService.post(`/tasks/${this.task.id}/unpublish`);
      this.task.published = false;
      this.$bvToast.toast('Task successfully unpublished', {
        title: 'Task saved',
        variant: 'success',
      });
      this.publishAction = false;
    },

    async publish() {
      this.publishAction = true;
      await ApiService.post(`/tasks/${this.task.id}/publish`);
      this.task.published = true;
      this.$bvToast.toast('Task successfully published', {
        title: 'Task saved',
        variant: 'success',
      });
      this.publishAction = false;
    },

    async refreshBalance() {
      try {
        this.refreshingBalance = true;
        const res = await ApiService.put(`/tasks/${this.task.id}/check-escrow-balance`);
        this.task = { ...this.task, ...res.data.data };
        this.refreshingBalance = false;
      } catch (err) {
        this.refreshingBalance = false;
      }
    },

    async getAgent() {
      if (this.task?.agentId) {
        const { data } = await ApiService.get(`/agents/${this.task.agentId}`);
        this.agent = data.data;
      }
    },
  },
};
</script>
