<template>
  <b-form @submit.prevent="search">
    <b-input-group class="mb-4">
      <b-form-input
        v-model="form.term"
        type="text"
        placeholder="Enter search term"/>
      <b-input-group-append>
        <b-button variant="info" @click.prevent="exportEmails">Export emails</b-button>
      </b-input-group-append>
      <b-input-group-append>
        <b-button variant="success" type="submit">Search</b-button>
      </b-input-group-append>
    </b-input-group>
    <div class="row">
      <div class="col-6">
        <b-form-datepicker v-model="form.from" placeholder="From" class="mb-2" />
      </div>
      <div class="col-6">
        <b-form-datepicker v-model="form.to" placeholder="to" class="mb-2" />
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  name: 'LeadSearchForm',
  data() {
    return {
      form: {
        term: '',
        from: null,
        to: null,
      },
    };
  },
  methods: {
    search() {
      this.$emit('search', this.form);
    },
    exportEmails() {
      this.$emit('export', this.form);
    },
  },
};
</script>

<style scoped>

</style>
