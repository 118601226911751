<template>
  <div ref="newsletterEditor" id="newsletterEditor"/>
</template>

<script>
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.css';
import grapesjs from 'grapesjs';
// eslint-disable-next-line no-unused-vars
import newsletterPlugin from 'grapesjs-preset-newsletter';
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Grapes',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    const { userToken } = this;
    this.editor = grapesjs.init({
      container: '#newsletterEditor',
      plugins: ['gjs-preset-newsletter'],
      storageManager: {
        autosave: false,
        autoload: false,
        type: 'remote',
      },
      assetManager: {
        upload: `${process.env.VUE_APP_API_URL}files/multiple`,
        params: {
          type: 'newsletter',
        },
        autoAdd: 0,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'omit',
      },
    });

    const panels = this.editor.Panels;

    panels.addButton('options', {
      id: 'save',
      className: 'fa fa-save',
      command: this.save,
      attributes: { title: 'Save' },
    });

    this.editor.setStyle(this.data.css);
    this.editor.setComponents(this.data.html);

    this.getAssets();

    this.editor.on('asset:upload:response', (res) => {
      this.editor.AssetManager.add(res.data.map((a) => ({
        src: `https://${process.env.VUE_APP_NEWSLETTER_BUCKET}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${a.fileName}`,
      })));
    });
  },
  methods: {
    save(e) {
      this.$emit('update', {
        html: e.getHtml(),
        css: e.getCss(),
      });
    },

    async getAssets() {
      const res = await ApiService.get('/newsletters/assets');
      this.editor.AssetManager.add(res.data.data.map((a) => ({
        src: `https://${process.env.VUE_APP_NEWSLETTER_BUCKET}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${a.fileName}`,
      })));
    },
  },
  computed: {
    userToken() {
      return this.$store.state.auth.token;
    },
  },
};
</script>

<style scoped>

</style>
