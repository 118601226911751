import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueScreen from 'vue-screen';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faSearch,
  faEnvelope,
  faLock,
  faTasks,
  faUsers,
  faStore,
  faUserSecret,
  faTimes,
  faArrowLeft,
  faArrowRight,
  faFile,
  faCheck,
  faArchive,
  faDollarSign,
  faComments,
  faBlog,
  faStickyNote,
  faShoppingCart,
  faStar as fasStar,
  faBolt,
  faHammer,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import App from './App.vue';
import router from './router';
import store from './store';
import ApiService from './services/ApiService';

// load main style file
import './assets/scss/style.scss';

// load layouts
import MainLayout from './layouts/MainLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';

// register layouts
Vue.component('main-layout', MainLayout);
Vue.component('auth-layout', AuthLayout);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(CKEditor);
Vue.use(VueFilterDateFormat);
Vue.use(VueScreen, 'bootstrap');

Vue.config.productionTip = false;

// load font awesome icons
library.add(
  faBars,
  faSearch,
  faEnvelope,
  faLock,
  faTasks,
  faUsers,
  faStore,
  faUserSecret,
  faTimes,
  faArrowLeft,
  faArrowRight,
  faFile,
  faCheck,
  faArchive,
  faDollarSign,
  faComments,
  faBlog,
  faStickyNote,
  faShoppingCart,
  fasStar,
  farStar,
  faBolt,
  faHammer,
);

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_API_URL, store);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
