<template>
  <nav class="mt-2">
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
        data-accordion="false">
      <!-- Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library -->
      <li class="nav-item">
        <router-link to="/tasks" class="nav-link">
          <font-awesome-icon icon="tasks" class="nav-icon"/>
          <p>
            Tasks
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/freelancers" class="nav-link">
          <font-awesome-icon icon="user-secret" class="nav-icon"/>
          <p>
            Freelancers
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/clients" class="nav-link">
          <font-awesome-icon icon="store" class="nav-icon"/>
          <p>
            Clients
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/agents" class="nav-link">
          <font-awesome-icon icon="bolt" class="nav-icon"/>
          <p>
            Agents
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/users" class="nav-link">
          <font-awesome-icon icon="users" class="nav-icon"/>
          <p>
            Users
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/subscriptions" class="nav-link">
          <font-awesome-icon icon="shopping-cart" class="nav-icon"/>
          <p>
            Subscriptions
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/payments" class="nav-link">
          <font-awesome-icon icon="dollar-sign" class="nav-icon"/>
          <p>
            Payments
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/leads" class="nav-link">
          <font-awesome-icon icon="comments" class="nav-icon"/>
          <p>
            Leads
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/blog" class="nav-link">
          <font-awesome-icon icon="blog" class="nav-icon"/>
          <p>
            Blog Posts
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/pages" class="nav-link">
          <font-awesome-icon icon="sticky-note" class="nav-icon"/>
          <p>
            Static Pages
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/testimonials" class="nav-link">
          <font-awesome-icon icon="comments" class="nav-icon"/>
          <p>
            Testimonials
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/categories" class="nav-link">
          <font-awesome-icon icon="hammer" class="nav-icon"/>
          <p>
            Categories & Skills
          </p>
        </router-link>
      </li>
      <li class="nav-item has-treeview menu-open">
        <router-link to="/newsletter" class="nav-link">
          <font-awesome-icon icon="envelope" class="nav-icon"/>
          <p>
            Newsletters
          </p>
        </router-link>
        <ul class="nav nav-treeview">
          <li class="nav-item">
            <router-link to="/newsletter" class="nav-link">
              <font-awesome-icon icon="envelope" class="nav-icon"/>
              <p>Newsletters</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/templates" class="nav-link">
              <font-awesome-icon icon="file" class="nav-icon"/>
              <p>Templates</p>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'MainMenu',
};
</script>

<style scoped>

</style>
