import { render, staticRenderFns } from "./CategoryListTitle.vue?vue&type=template&id=5125718c&scoped=true&"
import script from "./CategoryListTitle.vue?vue&type=script&lang=js&"
export * from "./CategoryListTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5125718c",
  null
  
)

export default component.exports