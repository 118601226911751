<template>
  <b-card header-bg-variant="danger" header="Categories & Skills">
    <h2>Categories</h2>
    <ul>
      <li v-for="c in categoriesData" :key="c.id">
        {{ c.name }}
      </li>
    </ul>
    <h2>Skills</h2>
    <ul>
      <li v-for="c in skillsData" :key="c.id">
        {{ c.name }}
      </li>
    </ul>
  </b-card>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'FreelancerSkills',
  props: {
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    skills: {
      type: Array,
      default() {
        return [];
      },
    },
    languageId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    categoriesData() {
      const ids = this.categories.map((c) => c.id);
      return this.$store.getters['util/getCategoryTranslations']?.filter((c) => ids.includes(c.id));
    },
    skillsData() {
      const ids = this.skills.map((c) => c.id);
      return this.$store.getters['util/getSkillTranslations']?.filter((c) => ids.includes(c.id));
    },
  },
};
</script>

<style scoped>

</style>
