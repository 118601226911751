<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <div class="small-box bg-info">
          <div class="inner">
            <h3>{{ numbers.send }}</h3>

            <p>Sent</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="info-box bg-success">
          <span class="info-box-icon"><font-awesome-icon icon="envelope"/></span>

          <div class="info-box-content">
            <span class="info-box-text">Delivered</span>
            <span class="info-box-number">{{ numbers.delivery }}</span>

            <div class="progress">
              <div class="progress-bar" :style="`width: ${deliveredPercentage}%`"></div>
            </div>
            <span class="progress-description">
              {{ deliveredPercentage }}%
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="info-box bg-warning">
          <span class="info-box-icon"><font-awesome-icon icon="envelope"/></span>

          <div class="info-box-content">
            <span class="info-box-text">Opened</span>
            <span class="info-box-number">{{ numbers.open }}</span>

            <div class="progress">
              <div class="progress-bar" :style="`width: ${openedPercentage}%`"></div>
            </div>
            <span class="progress-description">
              {{ openedPercentage }}%
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="info-box bg-danger">
          <span class="info-box-icon"><font-awesome-icon icon="envelope"/></span>

          <div class="info-box-content">
            <span class="info-box-text">Bounced</span>
            <span class="info-box-number">{{ numbers.bounce }}</span>

            <div class="progress">
              <div class="progress-bar" :style="`width: ${bouncedPercentage}%`"></div>
            </div>
            <span class="progress-description">
              {{ bouncedPercentage }}%
            </span>
          </div>
          <!-- /.info-box-content -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-card header="Clicked links" header-bg-variant="primary">
          <b-table striped :items="links" :fields="fields" id="my-table"/>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'NewsletterStats',
  data() {
    return {
      stats: null,
      fields: ['link', 'count'],
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    async getStats() {
      const res = await ApiService.get(`/newsletters/${this.$route.params.newsletterId}/sent/${this.$route.params.sentId}/statistics`);
      this.stats = res.data.data;
    },
  },
  computed: {
    numbers() {
      return this.stats ? this.stats.stats : {};
    },
    bouncedPercentage() {
      // eslint-disable-next-line no-mixed-operators
      return this.stats ? Math.floor(this.stats.stats.bounce / this.stats.stats.send * 100) : 0;
    },
    openedPercentage() {
      // eslint-disable-next-line no-mixed-operators
      return this.stats ? Math.floor(this.stats.stats.open / this.stats.stats.send * 100) : 0;
    },
    deliveredPercentage() {
      // eslint-disable-next-line no-mixed-operators
      return this.stats ? Math.floor(this.stats.stats.delivery / this.stats.stats.send * 100) : 0;
    },
    links() {
      if (this.stats) {
        const items = [];
        Object.keys(this.stats.links)
          .forEach((link) => {
            items.push({
              link,
              count: this.stats.links[link],
            });
          });
        return items;
      }
      return [];
    },
  },
};
</script>
