<template>
  <div class="row">
    <div class="col-8">
      <b-card header-bg-variant="primary" :header="agent.name">
        <b-form @submit.prevent="save">
          <b-form-group id="name" label="Name">
            <b-form-input v-model="agent.name"/>
          </b-form-group>

          <b-form-group id="countryId" label="Country">
            <b-form-select v-model="agent.countryId" :options="countries"/>
          </b-form-group>

          <b-form-group id="location" label="Location">
            <b-form-input v-model="agent.location"/>
          </b-form-group>

          <b-form-group id="resume" label="Resume">
            <wysiwyg-editor v-model="agent.about"/>
          </b-form-group>

          <b-form-group id="default" label="Default">
            <b-form-checkbox v-model="agent.default"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
    <div class="col-4">
      <b-card header-bg-variant="info" header="Info">
        <dl class="row mb-0">
          <dt class="col-sm-3">Id</dt>
          <dd class="col-sm-9">{{ agent.id }}</dd>

          <dt class="col-sm-3">Default</dt>
          <dd class="col-sm-9">
            <status-badge :status="agent.default" true-label="Yes"
                          false-label="No"/>
          </dd>

          <dt class="col-sm-3">Created at</dt>
          <dd class="col-sm-9">{{ agent.createdAt }}</dd>

          <dt class="col-sm-3">Updated at</dt>
          <dd class="col-sm-9">{{ agent.updatedAt }}</dd>

          <dt class="col-sm-3">Confirmed</dt>
          <dd class="col-sm-9">
            <status-badge :status="agent.confirmed" true-label="Confirmed"
                          false-label="Unconfirmed"/>
          </dd>
        </dl>

        <confirmation-toggle-button :confirmed="agent.confirmed" @toggle="toggleConfirmation"/>
      </b-card>

      <user-info-card v-if="user" :user="user" @toggled="userToggled"/>

      <b-card header-bg-variant="warning" header="Avatar">
        <div class="d-flex align-items-center justify-content-center">
          <image-uploader label="Avatar" title="Avatar" :value="agent.avatar"
                          :croppable="true" @input="imageAdded" @remove="imageRemoved"/>
        </div>
      </b-card>

      <freelancer-skills :categories="agent.categories" :skills="agent.skills"
                         :language-id="languageId"/>

      <b-card header-bg-variant="success" header="Tasks" body-class="p-0">
        <small-tasks-list :tasks="tasks"/>
      </b-card>
    </div>
  </div>
</template>

<script>
import set from 'lodash/set';
import UserInfoCard from '../../components/user/UserInfoCard.vue';
import ImageUploader from '../../components/form/ImageUploader.vue';
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import ApiService from '../../services/ApiService';
import SmallTasksList from '../../components/task/SmallTasksList.vue';
import FreelancerSkills from '../../components/user/FreelancerSkills.vue';
import StatusBadge from '../../components/StatusBadge.vue';
import ConfirmationToggleButton from '../../components/user/ConfirmationToggleButton.vue';

export default {
  name: 'Agent',
  components: {
    ConfirmationToggleButton,
    StatusBadge,
    FreelancerSkills,
    SmallTasksList,
    WysiwygEditor,
    ImageUploader,
    UserInfoCard,
  },
  data() {
    return {
      agent: {},
      tasks: [],
      user: null,
    };
  },
  methods: {
    userToggled() {
      set(this, 'agent.user.active', !this.agent.user.active);
    },

    async save() {
      try {
        await ApiService.put(`/agents/${this.agent.id}`, this.agent);
        this.$bvToast.toast('Agent successfully saved', {
          title: 'Agent saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Agent NOT saved', {
          title: 'ERROR',
          variant: 'danger',
        });
      }
    },

    async toggleConfirmation() {
      const data = {
        ...this.agent,
        confirmed: !this.agent.confirmed,
      };

      try {
        await ApiService.put(`/agents/${this.agent.id}`, data);
        this.$bvToast.toast('Agent successfully saved', {
          title: 'Agent saved',
          variant: 'success',
        });
        this.agent = data;
      } catch (err) {
        this.$bvToast.toast('Agent NOT saved', {
          title: 'ERROR',
          variant: 'danger',
        });
      }
    },

    imageAdded(data) {
      this.agent.avatar = data;
      this.agent.pictureId = data.id;
    },

    imageRemoved() {
      this.agent.avatar = null;
      this.agent.pictureId = null;
    },

    async getTasks() {
      const { agentId } = this.$route.params;
      const { data } = await ApiService.get(`/agents/${agentId}/tasks?all=true`);
      this.tasks = data.data;
    },

    async getAgent() {
      const { agentId } = this.$route.params;
      const { data } = await ApiService.get(`/agents/${agentId}`);
      this.agent = data.data;
    },

    async getUser() {
      const id = this.agent.userId;
      const { data } = await ApiService.get(`/users/${id}`);
      this.user = data.data;
    },
  },
  async created() {
    await this.getAgent();
    await this.getTasks();
    await this.getUser();
  },
  computed: {
    countries() {
      return this.$store.state.util.countries.map((c) => ({
        value: c.id,
        text: c.name,
      }));
    },

    /**
     * get language id
     * @returns {Number}
     */
    languageId() {
      return this.user?.languageId;
    },
  },
};
</script>

<style scoped>

</style>
