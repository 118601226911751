<template>
  <ckeditor :editor="editor"
            v-model="editorData"
            :config="editorConfig"
            @ready="prefill"/>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'WysiwygEditor',
  data() {
    return {
      editorData: '',
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          Base64UploadAdapter,
          LinkPlugin,
          ParagraphPlugin,
          Heading,
          Bold,
          Italic,
          Underline,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          CodeBlock,
          ImageInsert,
          Alignment,
          ImageResize,
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'alignment',
            '|',
            'link',
            'codeBlock',
            '|',
            'undo',
            'redo',
            '|',
            'insertImage',
          ],
        },
        image: {
          toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative',
          ],
        },
      },
    };
  },
  methods: {
    prefill() {
      this.editorData = this.value || '';
    },
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.editorData = this.value || '';
      }
    },
    editorData() {
      this.$emit('input', this.editorData);
    },
  },
};
</script>

<style>
  .ck-editor__editable {
    min-height: 400px !important;
  }
</style>
