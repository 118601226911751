<template>
  <router-link :to="`/categories/${category.id}`">
    {{ translation }}
  </router-link>
</template>

<script>
export default {
  name: 'CategoryListTitle',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    translation() {
      const eng = this.$store.state.util.languages.find((l) => l.code === 'en');
      const engTranslation = this.category.translations.find((t) => t.languageId === eng.id);
      return engTranslation.translation;
    },
  },
};
</script>

<style scoped>

</style>
