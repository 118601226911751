<template>
  <div v-if="payment" class="row">
    <div class="col-8">
      <b-card header-bg-variant="primary" :header="payment.hash">
        <b-form @submit.prevent="save">
          <b-form-group id="title" label="Title">
            <b-form-input v-model="payment.title"/>
          </b-form-group>

          <b-form-group id="amount" label="Amount">
            <b-form-input v-model="payment.amount"/>
          </b-form-group>

          <b-form-group id="currencyId" label="Currency">
            <b-form-select v-model="payment.currencyId" :options="currencies"/>
          </b-form-group>

          <b-form-group id="paymentMethodId" label="Payment Method">
            <b-form-select v-model="payment.paymentMethodId" :options="methods"/>
          </b-form-group>

          <b-form-group id="status" label="Status">
            <b-form-select v-model="payment.status" :options="statuses"/>
          </b-form-group>

          <b-form-group id="description" label="Description">
            <b-form-textarea v-model="payment.description"/>
          </b-form-group>

          <b-form-group id="notes" label="Notes">
            <b-form-textarea v-model="payment.notes"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
    <div class="col-4">
      <b-card header-bg-variant="success" header="Info">
        <dl class="row mb-0">
          <dt class="col-sm-3">ID</dt>
          <dd class="col-sm-9">{{ payment.id }}
          </dd>

          <dt class="col-sm-3">Created</dt>
          <dd class="col-sm-9">{{
              new Date(payment.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss')
            }}
          </dd>

          <dt class="col-sm-3">Updated</dt>
          <dd class="col-sm-9">{{
              new Date(payment.updatedAt) | dateFormat('DD.MM.YYYY HH:mm:ss')
            }}
          </dd>

          <dt class="col-sm-3">Created By</dt>
          <dd class="col-sm-9">
            <router-link :to="`/users/${payment.userId}`">{{ payment.userId }}
            </router-link>
          </dd>

          <dt class="col-sm-3">Hash code</dt>
          <dd class="col-sm-9">
            <strong>{{ payment.hash }}</strong>
          </dd>
        </dl>
      </b-card>
      <b-card header-bg-variant="warning" header="Updates" body-class="p-0">
        <b-list-group flush>
          <payment-update-small-item v-for="u in updates" :key="u.id" :update="u"/>
        </b-list-group>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import PaymentUpdateSmallItem from '../../components/payments/PaymentUpdateSmallItem.vue';

export default {
  name: 'Payment',
  components: { PaymentUpdateSmallItem },
  data() {
    return {
      payment: null,
      updates: [],
      saving: false,
      statuses: [
        {
          value: 0,
          text: 'Created',
        },
        {
          value: 1,
          text: 'Processing',
        },
        {
          value: 2,
          text: 'Pending',
        },
        {
          value: 3,
          text: 'Paid',
        },
        {
          value: 9,
          text: 'Failed',
        },
      ],
    };
  },
  async created() {
    const p = await ApiService.get(`/payments/${this.$route.params.paymentId}`);
    this.payment = p.data.data;

    await this.getUpdates();
  },
  methods: {
    async getUpdates() {
      const updates = await ApiService.get(`/payments/${this.$route.params.paymentId}/updates`);
      this.updates = updates.data.data;
    },
    async save() {
      this.saving = true;

      try {
        await ApiService.put(`/payments/${this.$route.params.paymentId}`, this.payment);
        this.saving = false;
        this.$bvToast.toast('Payment updated', {
          title: 'Payment saved',
          variant: 'success',
        });
        await this.getUpdates();
      } catch (err) {
        this.saving = false;
        this.$bvToast.toast('Payment NOT updated', {
          title: 'Payment NOT saved',
          variant: 'danger',
        });
      }
    },
  },
  computed: {
    currencies() {
      return this.$store.state.util.currencies.map((l) => ({
        value: l.id,
        text: l.code,
      }));
    },
    methods() {
      return this.$store.state.util.paymentMethods.map((m) => ({
        value: m.id,
        text: m.title,
      }));
    },
  },
};
</script>

<style scoped>

</style>
