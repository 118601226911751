<template>
<div class="row">
  <div class="col-md-4">
    <info-box label="Total" icon="archive" :number="total" type="info" />
  </div>
  <div class="col-md-4">
    <info-box label="Confirmed" icon="check" :number="published" type="green" />
  </div>
  <div class="col-md-4">
    <info-box label="Unconfirmed" icon="times" :number="unpublished" type="red" />
  </div>
</div>
</template>

<script>
import InfoBox from './InfoBox.vue';

export default {
  name: 'PublishedStats',
  components: { InfoBox },
  props: {
    statistics: {
      type: Array,
      required: true,
    },
  },
  computed: {
    total() {
      return this.statistics.map((s) => `${s.language.name}: ${s.total}`).join(', ');
    },
    published() {
      return this.statistics.map((s) => `${s.language.name}: ${s.published}`).join(', ');
    },
    unpublished() {
      return this.statistics.map((s) => `${s.language.name}: ${s.unpublished}`).join(', ');
    },
  },
};
</script>
