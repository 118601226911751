<template>
  <b-button variant="info" @click="clean">Clean</b-button>
</template>

<script>
import { capitalize } from 'lodash';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'CleanButton',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    clean() {
      this.$emit('input', capitalize(this.value));
    },
  },
};
</script>

<style scoped>

</style>
