<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="New newsletter data">
        <b-form @submit.prevent="save">
          <b-form-group id="name" label="Name">
            <b-form-input v-model="newsletter.name"/>
          </b-form-group>
          <b-form-group id="template" label="Type">
            <b-form-select v-model="newsletter.templateId" :options="templates"/>
          </b-form-group>
          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'NewsletterCreate',
  data() {
    return {
      newsletter: {
        name: '',
        templateId: null,
      },
      templates: [],
    };
  },
  mounted() {
    this.setTemplates();
  },
  methods: {
    async setTemplates() {
      const res = await ApiService.get('/templates');
      this.templates = res.data.data.map((t) => ({
        value: t.id,
        text: t.name,
      }));
    },
    save() {
      ApiService.post('/newsletters', this.newsletter)
        .then((res) => {
          this.$bvToast.toast('Newsletter successfully saved', {
            title: 'Newsletter saved',
            variant: 'success',
          });
          this.$router.push(`/newsletter/${res.data.data.id}`);
        });
    },
  },
};
</script>

<style scoped>

</style>
