<template>
  <b-list-group-item class="flex-column align-items-start">
    <div class="mb-3 d-flex w-100 justify-content-between">
      <h5 class="mb-1 d-flex align-items-center">
        <payment-status-badge class="mr-3" v-if="update.toStatus !== update.fromStatus"
                              :status="update.fromStatus"/>
        <span class="mr-3" v-if="update.toStatus !== update.fromStatus">-></span>
        <payment-status-badge :status="update.toStatus"/>
      </h5>
      <small>{{ new Date(update.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}</small>
    </div>

    <h6 class="mb-1 font-weight-bold">Description:</h6>
    <p class="mb-2">
      {{ update.description }}
    </p>

    <h6 class="mb-1 font-weight-bold">Notes:</h6>
    <p class="mb-0">
      {{ update.notes }}
    </p>
  </b-list-group-item>
</template>

<script>
import PaymentStatusBadge from './PaymentStatusBadge.vue';

export default {
  name: 'PaymentUpdateSmallItem',
  components: { PaymentStatusBadge },
  props: {
    update: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
