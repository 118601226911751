<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        <router-link to="/">
          CryptoTask <strong>Admin</strong>
        </router-link>
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body">
          <slot/>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'AuthLayout',
};
</script>
