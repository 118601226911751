<template>
  <div class="row">
    <div class="col-12">
      <search-form @search="search"/>
      <b-card header="Users" header-bg-variant="primary">
        <b-table striped :items="usersProvider" :fields="fields" :per-page="perPage"
                 :current-page="currentPage" id="my-table" :filter="filter">
          <template v-slot:cell(email)="data">
            <router-link :to="`/users/${data.item.id}`">{{ data.item.email }}</router-link>
          </template>
          <template v-slot:cell(lastLogin)="data">
            {{ new Date(data.item.lastLogin) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
          </template>
        </b-table>

        <template v-slot:footer class="clearfix">
          <b-pagination
            class="m-0 float-right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"/>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import SearchForm from '@/components/user/SearchForm.vue';
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'UsersSearch',
  components: {
    SearchForm,
  },
  data() {
    return {
      fields: ['email', 'emailConfirmed', 'active', 'lastLogin'],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      filter: null,
    };
  },
  methods: {
    search(e) {
      this.filter = e;
    },
    usersProvider(ctx) {
      const q = ctx.filter ? ctx.filter.q : '';
      return ApiService.get(`/users?page=${ctx.currentPage}&perPage=${ctx.perPage}&q=${encodeURIComponent(q)}`)
        .then((res) => {
          // eslint-disable-next-line no-underscore-dangle
          const users = res.data.data;

          this.rows = res.data.meta.total;

          return users || [];
        });
    },
  },
};
</script>

<style scoped>

</style>
