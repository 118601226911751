<template>
  <b-badge :variant="s.variant">{{ s.label }}</b-badge>
</template>

<script>
export default {
  name: 'PaymentStatusBadge',
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      statuses: {
        0: {
          variant: 'info',
          label: 'Created',
        },
        1: {
          variant: 'warning',
          label: 'Processing',
        },
        2: {
          variant: 'success',
          label: 'Pending',
        },
        3: {
          variant: 'success',
          label: 'Paid',
        },
        9: {
          variant: 'danger',
          label: 'Failed',
        },
      },
    };
  },
  computed: {
    s() {
      return this.statuses[this.status];
    },
  },
};
</script>

<style scoped>

</style>
