<template>
  <div>
    <p class="login-box-msg">Sign in to start your session</p>

    <b-form @submit.prevent="login">
      <div class="input-group mb-3">
        <input type="email" class="form-control" placeholder="Email" v-model="form.email"/>
        <div class="input-group-append">
          <div class="input-group-text">
            <font-awesome-icon icon="envelope"/>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input type="password" class="form-control" placeholder="Password" v-model="form.password"/>
        <div class="input-group-append">
          <div class="input-group-text">
            <font-awesome-icon icon="lock"/>
          </div>
        </div>
      </div>

      <!-- <div class="d-flex justify-content-end mb-2">
        <vue-hcaptcha ref="hcap" :sitekey="siteKey" @verify="handleVerified"/>
      </div> -->

      <div class="row">
        <!-- /.col -->
        <div class="offset-8 col-4">
          <button type="submit" class="btn btn-primary btn-block">Sign In</button>
        </div>
        <!-- /.col -->
      </div>
    </b-form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
// import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Login',
  // components: { VueHcaptcha },
  data() {
    return {
      form: {
        email: null,
        password: null,
        token: null,
      },
      sending: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    siteKey() {
      return process.env.VUE_APP_HCAPTCHA_SITE_KEY;
    },
  },
  methods: {
    handleVerified(token) {
      this.form = {
        ...this.form,
        token,
      };
    },
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sending = true;

        try {
          await this.$store.dispatch('auth/login', this.form);
          this.form = {};
          this.$v.$reset();
          this.sending = false;
          await this.$router.replace('/subscriptions');
        } catch (err) {
          this.sending = false;
          this.$bvToast.toast('User not administrator', {
            title: 'Unauthorized',
            variant: 'danger',
          });
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
