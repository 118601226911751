<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-card header="Subscriptions" header-bg-variant="primary">
          <b-table striped :items="itemsProvider" :fields="fields" :per-page="perPage"
                   :current-page="currentPage" id="my-table">
            <template v-slot:cell(id)="data">
              <router-link :to="`/subscriptions/${data.item.id}`">{{ data.item.id }}</router-link>
            </template>
            <template v-slot:cell(createdAt)="data">
              {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
            </template>
            <template v-slot:cell(qty)="data">
              {{ data.item.qty }} {{ data.item.unit }}
            </template>
            <template v-slot:cell(package)="data">
              {{ data.item.Package.title }}
            </template>
            <template v-slot:cell(price)="data">
              <price :amount="data.item.price" :currency-id="data.item.currencyId" />
            </template>
            <template v-slot:cell(status)="data">
              <subscription-status-badge :status="data.item.status" />
            </template>
          </b-table>

          <template v-slot:footer class="clearfix">
            <b-pagination
              class="m-0 float-right"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"/>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import SubscriptionStatusBadge from '../../components/subscriptions/SubscriptionStatusBadge.vue';
import Price from '../../components/ui/Price.vue';

export default {
  name: 'SubscriptionsList',
  components: { SubscriptionStatusBadge, Price },
  data() {
    return {
      fields: ['id', 'package', 'qty', 'price', 'status', 'createdAt'],
      currentPage: 1,
      perPage: 20,
      rows: 0,
    };
  },
  methods: {
    async itemsProvider(ctx) {
      const res = await ApiService.get(`/subscriptions?page=${ctx.currentPage}&perPage=${ctx.perPage}`);
      this.rows = res.data.meta.total;
      return res.data.data;
    },
  },
};
</script>

<style scoped>

</style>
