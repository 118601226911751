<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="Update Category">
        <b-form @submit.prevent="save">

          <template v-for="t in form.translations">
            <category-translation-input :key="t.languageId" :translation="t" @input="updated"/>
          </template>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import CategoryTranslationInput from '../../components/categories/CategoryTranslationInput.vue';
import ApiService from '../../services/ApiService';

export default {
  name: 'CategoryEdit',
  components: { CategoryTranslationInput },
  data() {
    return {
      form: {
        translations: [],
      },
    };
  },
  methods: {
    async getCategory() {
      const { data } = await ApiService.get(`/categories/${this.$route.params.categoryId}`);
      this.form = data.data;
    },
    updated(updated) {
      this.form.translations = this.form.translations.map((t) => {
        if (t.languageId === updated.languageId) {
          return updated;
        }
        return t;
      });
    },
    async save() {
      try {
        await ApiService.put(`/categories/${this.$route.params.categoryId}`, this.form);
        this.$bvToast.toast('Category successfully saved', {
          title: 'Category saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Category NOT saved', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },
  },
  mounted() {
    this.getCategory();
  },
};
</script>

<style scoped>

</style>
