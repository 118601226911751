<template>
  <b-list-group-item :to="`/subscriptions/${subscription.id}`"
                     class="flex-column align-items-start">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1 d-flex align-items-center">
        {{ subscription.Package.name }}
        <subscription-status-badge :status="subscription.status" class="ml-3" />
      </h5>
      <small>{{ new Date(subscription.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}</small>
    </div>

    <p class="mb-1 d-flex align-items-center">
      Price:
      <strong>
        <price :amount="subscription.price" :currency-id="subscription.currencyId"
               class="mr-1 ml-1"/>
      </strong>
      | Quantity:
      <strong class="ml-1">{{ subscription.qty }} {{ subscription.unit }}</strong>
    </p>

    <p class="mb-0" v-if="subscription.from && subscription.to">
      From: <strong>{{ new Date(subscription.from) | dateFormat('DD.MM.YYYY HH:mm:ss') }}</strong>
      To: <strong>{{ new Date(subscription.to) | dateFormat('DD.MM.YYYY HH:mm:ss') }}</strong>
    </p>
  </b-list-group-item>
</template>

<script>
import Price from '../ui/Price.vue';
import SubscriptionStatusBadge from './SubscriptionStatusBadge.vue';

export default {
  name: 'SubscriptionSmallItem',
  components: { SubscriptionStatusBadge, Price },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
