<template>
  <div>
    <div class="p-3">
      <div><strong>Title:</strong> {{ project.title }}</div>
      <div><strong>URL:</strong> {{ project.link }}</div>
      <div><strong>Tags:</strong> {{ project.tags }}</div>
      <p>{{ project.description }}</p>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'FreelancerProject',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
};
</script>
