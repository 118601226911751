import { render, staticRenderFns } from "./Grapes.vue?vue&type=template&id=832a9920&scoped=true&"
import script from "./Grapes.vue?vue&type=script&lang=js&"
export * from "./Grapes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "832a9920",
  null
  
)

export default component.exports