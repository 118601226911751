const initialState = {
  toasts: [],
  sidebarCollapsed: false,
};

const actions = {};

const mutations = {
  /**
   * Add toast
   * @param state
   * @param {Object} toast
   * @param {String} toast.title
   * @param {String} toast.text
   * @param {String} toast.type
   */
  addToast(state, toast) {
    state.toasts.push(toast);
  },

  /**
   * Remove toast by index
   * @param state
   * @param {Integer} toastIndex
   */
  removeToast(state, toastIndex) {
    state.toasts.splice(toastIndex, 1);
  },

  /**
   * Toggle sidebar display
   * @param state
   */
  toggleSidebar(state) {
    state.sidebarCollapsed = !state.sidebarCollapsed;
  },

  /**
   * Hide sidebar
   * @param state
   */
  hideSidebar(state) {
    state.sidebarCollapsed = true;
  },
};

const getters = {};

const store = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};

export default store;
