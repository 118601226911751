<template>
  <grapes v-if="template" :data="template" @update="save"/>
</template>

<script>
import Grapes from '../../components/newsletters/Grapes.vue';
import ApiService from '../../services/ApiService';

export default {
  name: 'TemplateBuilder',
  components: { Grapes },
  data() {
    return {
      template: null,
    };
  },
  mounted() {
    ApiService.get(`/templates/${this.templateId}`)
      .then((res) => {
        this.template = res.data.data;
      });
  },
  computed: {
    templateId() {
      return this.$route.params.templateId;
    },
  },
  methods: {
    async save(e) {
      const data = { ...this.template, ...e };
      await ApiService.put(`/templates/${this.templateId}`, data);
      this.$bvToast.toast('Template successfully saved', {
        title: 'Template saved',
        variant: 'success',
      });
    },
  },
};
</script>
