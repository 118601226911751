<template>
  <div class="row">
    <div class="col-12">
      <b-card :header="title" header-bg-variant="primary">
        <b-table striped :items="items" :fields="fields" id="my-table">
          <template v-slot:cell(createdAt)="data">
            {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
          </template>
          <template v-slot:cell(stats)="data">
            <router-link
              :to="`/newsletter/${data.item.newsletterId}/sent/${data.item.id}/statistics`">
              Statistics
            </router-link>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'NewsletterSent',
  data() {
    return {
      newsletter: null,
      items: [],
      fields: ['id', 'subject', 'createdAt', 'stats'],
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      const res = await ApiService.get(`/newsletters/${this.$route.params.newsletterId}/sent`);
      this.items = res.data.data;
    },
    async getNewsletter() {
      const res = await ApiService.get(`/newsletters/${this.$route.params.newsletterId}`);
      this.newsletter = res.data.data;
    },
  },
  computed: {
    title() {
      return `Sent Newsletter - ${this.newsletter ? this.newsletter.name : ''}`;
    },
  },
};
</script>
