<template>
  <b-form @submit.prevent="search">
    <b-input-group class="mb-4">
      <b-form-input
        v-model="form.q"
        type="text"
        placeholder="Enter search term"/>
      <b-input-group-append>
        <b-button variant="danger" @click.prevent="clear">Clear</b-button>
      </b-input-group-append>
      <b-input-group-append>
        <b-button variant="success" type="submit">Search</b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="row">
      <div class="col-md-6">
        <label class="text-nowrap mr-2 mb-0">Published status: </label>
        <b-form-select :options="publishedOptions" v-model="form.published" class="mr-3"/>
      </div>
      <div class="col-md-6">
        <label class="text-nowrap mr-2 mb-0">Confirmed status: </label>
        <b-form-select :options="confirmedOptions" v-model="form.confirmed"/>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  name: 'FreelancersSearchForm',
  data() {
    return {
      form: {
        q: '',
        published: '',
        confirmed: '',
      },
    };
  },
  methods: {
    clear() {
      this.form.q = '';
      this.search();
    },
    search() {
      this.$store.commit('freelancers/setSearchParams', this.form);
      this.$emit('search', this.form);
    },
  },
  mounted() {
    const { searchParams } = this.$store.state.freelancers;

    if (searchParams) {
      this.form = { ...searchParams };
    }
  },
  computed: {
    publishedOptions() {
      return [
        {
          value: '',
          text: 'All',
        },
        {
          value: true,
          text: 'Published',
        },
        {
          value: false,
          text: 'Unpublished',
        },
      ];
    },

    confirmedOptions() {
      return [
        {
          value: '',
          text: 'All',
        },
        {
          value: true,
          text: 'Confirmed',
        },
        {
          value: false,
          text: 'Unconfirmed',
        },
      ];
    },
  },
};
</script>
