<template>
  <standard-wrapper>
    <template slot="title">Dashboard</template>

    <div class="row">
      <div class="col-md-12">
        Welcome
      </div>
    </div>
  </standard-wrapper>
</template>

<script>
import StandardWrapper from '../components/wrappers/StandardWrapper.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Dashboard',
  components: { StandardWrapper },
};
</script>
