<template>
  <div v-if="application" class="row">
    <div class="col-8">
      <b-card header-bg-variant="primary" :header="`Application - ${application.task.title}`">
        <dl>
          <dt>Task</dt>
          <dd>
            <router-link :to="'/tasks/' + application.taskId">{{ application.task.title }}
            </router-link>
          </dd>

          <dt>Client</dt>
          <dd>
            <router-link :to="'/clients/' + application.clientId">{{ application.client.name }}
            </router-link>
          </dd>

          <dt>Freelancer</dt>
          <dd>
            <router-link :to="'/freelancers/' + application.freelancerId">
              {{ application.freelancer.name }}
            </router-link>
          </dd>

          <dt>Letter</dt>
          <dd>{{ application.letter }}</dd>

          <dt>Crypto Payout address</dt>
          <dd>{{ application.cryptoPayoutAddress || '-' }}</dd>

          <dt>Status</dt>
          <dd>
            <application-status-badge :application="application"/>
          </dd>
        </dl>
      </b-card>
    </div>
    <div class="col-4">
      <b-card header-bg-variant="success" header="Recommended">
        <dl>
          <dt>Recommended</dt>
          <dd>{{ application.recommended }}</dd>
        </dl>

        <b-button variant="success" @click="toggleRecommended" :disabled="togglingRecommended">
          Toggle
        </b-button>
      </b-card>
      <application-chat :application="application" />
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import ApplicationStatusBadge from '../../components/applications/ApplicationStatusBadge.vue';
import ApplicationChat from '../../components/applications/ApplicationChat.vue';

export default {
  name: 'Application',
  components: { ApplicationChat, ApplicationStatusBadge },
  data() {
    return {
      application: null,
      togglingRecommended: false,
    };
  },
  async mounted() {
    const appRes = await ApiService.get(`/applications/${this.$route.params.applicationId}`);
    this.application = appRes.data.data;
  },
  methods: {
    async toggleRecommended() {
      this.togglingRecommended = true;
      const res = await ApiService.put(`/applications/${this.$route.params.applicationId}/toggle-recommended`);
      this.application.recommended = res.data.data.recommended;
      this.togglingRecommended = false;
    },
  },
};
</script>

<style scoped>

</style>
