<template>
  <div class="row">
    <div class="col-12">
      <b-button variant="primary" class="mb-3" @click="create">Create new client</b-button>
      <search-form @search="search"/>
      <published-stats v-if="statisticsData" :statistics="statistics"/>
      <b-card header="Clients" header-bg-variant="primary">
        <b-table striped :items="clientsProvider" :fields="fields" :per-page="perPage"
                 :current-page="currentPage" id="my-table" :filter="filter">
          <template v-slot:cell(id)="data">
            <router-link :to="`/clients/${data.item.id}`">{{ data.item.id }}</router-link>
          </template>
          <template v-slot:cell(name)="data">
            <router-link :to="`/clients/${data.item.id}`">{{ data.item.name }}</router-link>
          </template>
          <template v-slot:cell(published)="data">
            <status-badge :status="data.item.published"/>
          </template>
          <template v-slot:cell(createdAt)="data">
            {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
          </template>
        </b-table>

        <template v-slot:footer class="clearfix">
          <b-pagination
            class="m-0 float-right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"/>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import SearchForm from '../../components/user/SearchForm.vue';
import StatusBadge from '../../components/StatusBadge.vue';
import PublishedStats from '../../components/ui/PublishedStats.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ClientsSearch',
  components: {
    StatusBadge,
    SearchForm,
    PublishedStats,
  },
  data() {
    return {
      fields: ['id', 'name', 'email', 'location', 'published', 'createdAt'],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      filter: '',
      statisticsData: null,
    };
  },
  methods: {
    search(e) {
      this.filter = { ...e };
    },
    clientsProvider(ctx) {
      const q = ctx.filter ? ctx.filter.q : '';

      return ApiService.get(`/clients?page=${ctx.currentPage}&perPage=${ctx.perPage}&q=${encodeURIComponent(q)}`)
        .then((res) => {
          // eslint-disable-next-line no-underscore-dangle
          const clients = res.data.data.hits.map((h) => ({ id: h._id, ...h._source }));

          this.rows = res.data.data.total.value;

          return clients || [];
        });
    },

    async getStatistics() {
      const res = await ApiService.get('/statistics/clients');
      this.statisticsData = res.data.data;
    },

    create() {
      this.$router.push('/clients/create');
    },
  },
  computed: {
    statistics() {
      const languages = this.$store.getters['util/getEnabledLanguagesMap'];
      if (languages) {
        return this.statisticsData.map((s) => ({
          ...s,
          unpublished: s.total - s.published,
          language: languages[s.languageId],
        }));
      }
      return [];
    },
  },
  mounted() {
    this.getStatistics();
  },
};
</script>
