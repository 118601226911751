function extract(arr, languageId) {
  return arr.map((s) => {
    const translation = (s.translations && s.translations.length)
      ? s.translations
        .find((t) => t.languageId === languageId)
      : null;
    return {
      ...s,
      name: translation ? translation.displayTranslation : '',
    };
  });
}

export default extract;
