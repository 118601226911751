<template>
  <b-list-group-item :to="`/payments/${payment.id}`"
                     class="flex-column align-items-start">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1 d-flex align-items-center">
        {{ payment.hash }}
        <payment-status-badge class="ml-3" :status="payment.status" />
      </h5>
      <small>{{
          new Date(payment.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss')
        }}</small>
    </div>

    <p class="mb-1 d-flex align-items-center">
      Amount:
      <strong>
        <price :amount="payment.amount" :currency-id="payment.currencyId"
               class="mr-1 ml-1"/>
      </strong>
    </p>

    <p class="mb-0">
      {{ payment.description }}
    </p>
  </b-list-group-item>
</template>

<script>
import Price from '../ui/Price.vue';
import PaymentStatusBadge from './PaymentStatusBadge.vue';

export default {
  name: 'PaymentSmallItem',
  components: {
    PaymentStatusBadge,
    Price,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
