<template>
  <div>
    <b-button-group class="mb-3">
      <b-button to="/newsletter/create">
        Create new newsletter
      </b-button>
    </b-button-group>
    <div class="row">
      <div class="col-12">
        <b-card header="Newsletters" header-bg-variant="primary">
          <b-table striped :items="items" :fields="fields" id="my-table">
            <template v-slot:cell(name)="data">
              <router-link :to="`/newsletter/${data.item.id}`">{{ data.item.name }}</router-link>
            </template>
            <template v-slot:cell(createdAt)="data">
              {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
            </template>
            <template v-slot:cell(send)="data">
              <router-link :to="`newsletter/${data.item.id}/send`">Send</router-link>
            </template>
            <template v-slot:cell(sent)="data">
              <router-link :to="`newsletter/${data.item.id}/sent`">Sent items</router-link>
            </template>
          </b-table>

          <template v-slot:footer class="clearfix">
            <b-pagination
              class="m-0 float-right"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="getItems"
              aria-controls="my-table"/>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'NewslettersList',
  data() {
    return {
      items: [],
      fields: ['id', 'name', 'createdAt', 'send', 'sent'],
      perPage: 10,
      currentPage: 1,
      rows: 0,
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      const res = await ApiService.get(`/newsletters?page=${this.currentPage}&perPage=${this.perPage}`);
      this.items = res.data.data || [];
      this.rows = res.data.meta.total;
    },
  },
};
</script>
