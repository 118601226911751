<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <v-select
      :options="options"
      :class="[classes]"
      :value="value"
      :multiple="true"
      :placeholder="placeholder"
      :label="optionsLabel"
      :reduce="reduce"
      @input="$emit('input', $event)"
    >
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'InputTags',
  components: {
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    optionsLabel: {
      type: String,
      default: 'label',
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    validation: {
      type: Object,
      default: null,
    },
    reduce: {
      type: Function,
      default: (i) => i,
    },
  },
  computed: {
    classes() {
      return this.validation && this.validation.$dirty && this.validation.$invalid
        ? 'is-invalid'
        : '';
    },
  },
};
</script>
