<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="Update Blog Post">
        <b-form @submit.prevent="save">
          <image-uploader label="Intro image" title="Intro image" :value="post.image"
                          :croppable="false" @input="imageAdded" @remove="imageRemoved"/>

          <b-form-group id="title" label="Title">
            <b-form-input v-model="post.title"/>
          </b-form-group>

          <b-form-group id="description" label="Content">
            <wysiwyg-editor v-model="post.text"/>
          </b-form-group>

          <b-form-group id="type" label="Language">
            <b-form-select v-model="post.languageId" :options="languages"/>
          </b-form-group>

          <div class="d-flex align-items-center justify-content-between">
            <b-button type="submit" variant="primary">Save</b-button>
            <b-button variant="danger" @click.prevent="deletePost">Delete</b-button>
          </div>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import ApiService from '../../services/ApiService';
import ImageUploader from '../../components/form/ImageUploader.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'BlogCreate',
  components: {
    WysiwygEditor,
    ImageUploader,
  },
  data() {
    return {
      post: {
        title: '',
        text: '',
        languageId: null,
        imageId: null,
        image: null,
      },
    };
  },
  methods: {
    async save() {
      try {
        await ApiService.put(`/posts/${this.$route.params.postId}`, this.post);
        this.$bvToast.toast('Post successfully saved', {
          title: 'Post saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Post NOT saved', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },
    async getPost() {
      const res = await ApiService.get(`/posts/${this.$route.params.postId}`);
      this.post = res.data.data;
    },

    imageAdded(data) {
      this.post.image = data;
      this.post.imageId = data.id;
    },

    imageRemoved() {
      this.post.image = null;
      this.post.imageId = null;
    },
    async deletePost() {
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Are you sure?');

        if (confirm) {
          await ApiService.delete(`/posts/${this.$route.params.postId}`);
          this.$bvToast.toast('Post successfully deleted', {
            title: 'Post deleted',
            variant: 'success',
          });
          await this.$router.push('/blog');
        }
      } catch (err) {
        this.$bvToast.toast('Post NOT deleted', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },
  },
  mounted() {
    this.getPost();
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        value: l.id,
        text: l.name,
      }));
    },
  },
};
</script>
