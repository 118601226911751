<template>
  <div>
    <b-badge variant="success" v-if="status">{{trueLabel}}</b-badge>
    <b-badge variant="danger" v-if="!status">{{falseLabel}}</b-badge>
  </div>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: Boolean,
    },
    trueLabel: {
      type: String,
      default: 'Published',
    },
    falseLabel: {
      type: String,
      default: 'UnPublished',
    },
  },
};
</script>

<style scoped>

</style>
