<template>
  <div>
    <div
      class="uploader-container image-uploader mb-2"
      @drop.prevent="onDragDrop"
      @dragover.prevent
    >
      <div class="uploader h-100">
        <template v-if="!uploading">
          <div
            v-if="!imageUrl"
            class="text d-flex flex-column justify-content-center align-items-center"
          >
            <b-spinner v-if="uploading" class="text-dark" large/>
            <p class="text-center">
              <a href="#" @click="selectFile"><u>{{ label }}</u></a>
            </p>
          </div>

          <div
            v-if="imageUrl"
            class="text image"
            :style="{'background-image': `url(${imageUrl})`}"
          >
            <div class="overlay flex-column align-items-center justify-content-center">
              <p class="text-center">
                <a href="#" class="text-white" @click="selectFile">
                  <u>Update</u>
                </a>
                or
                <a href="#" class="text-white" @click="removeFile">
                  <u>Remove</u>
                </a>
                image
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="h-100 d-flex flex-column align-items-center justify-content-center">
            <div>uploading...</div>
            <h3>{{ uploadPercentage }}%</h3>
          </div>
        </template>

        <input
          ref="file"
          type="file"
          :name="fileName"
          :disabled="uploading"
          @change="onFileChange($event.target.files)"
        >
      </div>
    </div>

    <b-form-invalid-feedback :force-show="!!error">
      {{ error }}
    </b-form-invalid-feedback>

    <b-modal
      ref="cropModal"
      :title="modalTitle"
      ok-title="Ok"
      cancel-title="Cancel"
      :no-close-on-backdrop="true"
      @shown="initModal"
      @ok="modifyImage"
    >
      <vue-cropper
        v-show="selectedFile"
        ref="cropper"
        :aspect-ratio="aspectRatio"
        :src="selectedFile"
        @crop="checkDimensions"
      />
      <b-form-text v-if="minWidth && minHeight">
        Min: {{ minWidth }}px x {{ minHeight }}px
      </b-form-text>
      <b-form-text>
        Selected: {{ width }}px x {{ height }}px
      </b-form-text>

      <b-form-invalid-feedback :force-show="!!error">
        {{ error }}
      </b-form-invalid-feedback>
    </b-modal>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ImageUploader',
  components: { VueCropper },
  props: {
    value: {
      type: Object,
      default: null,
    },
    fileName: {
      type: String,
      default: 'file',
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'public',
    },
    validation: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    croppable: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 1,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    minWidth: {
      type: Number,
      default: null,
    },
    minHeight: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      imageFile: null,
      imageUrl: null,
      maxSize: process.env.VUE_APP_FILESIZE_LIMIT,
      error: null,
      uploading: false,
      uploadPercentage: 0,
      modalShow: false,
      selectedFile: null,
      mimeType: null,
      height: 0,
      width: 0,
      allowedFormats: [
        'image/jpeg',
        'image/gif',
        'image/tiff',
        'image/bmp',
        'image/png',
      ],
    };
  },
  watch: {
    value(n) {
      if (n) {
        const imageRequest = JSON.stringify({
          bucket: process.env.VUE_APP_PUBLIC_BUCKET,
          key: n.fileName,
          edits: {
            ...this.options,
            rotate: null,
          },
        });
        this.imageUrl = `${process.env.VUE_APP_IMAGE_BASE_URL}/${btoa(imageRequest)}`;
      }
    },
  },
  mounted() {
    if (this.value && this.value.fileName) {
      const imageRequest = JSON.stringify({
        bucket: process.env.VUE_APP_PUBLIC_BUCKET,
        key: this.value.fileName,
        edits: this.options,
      });
      this.imageUrl = `${process.env.VUE_APP_IMAGE_BASE_URL}/${btoa(imageRequest)}`;
    }
  },
  methods: {
    selectFile(e) {
      e.preventDefault();
      this.$refs.file.click();
    },
    onFileChange(file) {
      // eslint-disable-next-line prefer-destructuring
      this.imageFile = file[0];
      this.validateImage();
    },
    onDragDrop(e) {
      // eslint-disable-next-line prefer-destructuring
      this.imageFile = e.dataTransfer.files[0];
      this.validateImage();
    },

    validateImage() {
      const { maxSize } = this;
      const size = this.imageFile.size / maxSize / maxSize;
      this.mimeType = this.imageFile.type;
      this.error = null;
      if (!this.mimeType.match('image.*') || !this.allowedFormats.includes(this.mimeType)) {
        // check whether the upload is an image
        this.error = 'File not an image';
      } else if (size > 1) {
        // check whether the size is greater than the size limit
        this.error = 'File too big';
      } else {
        this.prepareImage();
      }
    },

    prepareImage() {
      if (this.croppable) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;

          this.$refs.cropModal.show();
        };
        reader.readAsDataURL(this.imageFile);
      } else {
        this.uploadFile();
      }
    },

    initModal() {
      this.$refs.cropper.replace(this.selectedFile);
    },

    modifyImage() {
      if (!this.error) {
        this.$refs.cropper.getCroppedCanvas()
          .toBlob((blob) => {
            this.imageFile = blob;
            this.uploadFile();
          }, this.mimeType);
      }
    },

    checkDimensions(event) {
      this.height = Math.floor(event.detail.height);
      this.width = Math.floor(event.detail.width);

      this.error = null;

      if (this.minHeight && event.detail.height < this.minHeight) {
        this.error = 'Image height too small';
      }

      if (this.minWidth && event.detail.height < this.minWidth) {
        this.error = 'Image width too small';
      }
    },

    async uploadFile() {
      if (!this.error) {
        this.uploading = true;
        const fileType = this.mimeType.split('/');
        // Append file into FormData and turn file into image URL
        const formData = new FormData();
        formData.append('type', this.type);
        formData.append(this.fileName, this.imageFile, `image.${fileType[1]}`);

        // submit file to api
        const response = await ApiService.post('/files', formData, {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentage = (progressEvent.loaded / progressEvent.total) * 100;
            this.uploadPercentage = Math.round(percentage);
          },
        });

        // Emit response as file entry
        this.$emit('input', response.data.data);

        this.uploading = false;
        this.uploadPercentage = 0;
      }
    },

    removeFile(e) {
      e.preventDefault();
      this.imageUrl = null;
      this.$emit('remove');
    },
  },
};
</script>
