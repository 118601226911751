<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="Create Page">
        <b-form @submit.prevent="save">
          <b-form-group id="title" label="Title">
            <b-form-input v-model="page.title"/>
          </b-form-group>

          <b-form-group id="description" label="Content">
            <wysiwyg-editor v-model="page.text"/>
          </b-form-group>

          <b-form-group id="type" label="Language">
            <b-form-select v-model="page.languageId" :options="languages"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'PageCreate',
  components: { WysiwygEditor },
  data() {
    return {
      page: {
        title: '',
        text: '',
        languageId: null,
      },
    };
  },
  methods: {
    async save() {
      try {
        await ApiService.post('/pages', this.page);
        this.$bvToast.toast('Page successfully saved', {
          title: 'Page saved',
          variant: 'success',
        });
        await this.$router.replace('/pages');
      } catch (err) {
        this.$bvToast.toast('Page NOT saved', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        text: l.name,
        value: l.id,
      }));
    },
  },
};
</script>
