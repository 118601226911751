<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" :header="title">
        <b-form @submit.prevent="send">
          <b-form-group id="subject" label="Subject">
            <b-form-input v-model="form.subject"/>
          </b-form-group>
          <b-form-group id="list" label="Type">
            <b-form-select v-model="form.list" :options="lists"/>
          </b-form-group>
          <b-form-group id="role" label="Role">
            <b-form-select v-model="form.role" :options="roles"/>
          </b-form-group>
          <b-form-group v-if="form.role === 'custom'" id="custom" label="Custom">
            <b-form-textarea v-model="form.custom"/>
          </b-form-group>
          <b-button type="submit" variant="primary">Send</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'NewsletterSend',
  data() {
    return {
      newsletter: null,
      form: {
        subject: '',
        list: 'all',
        role: 'all',
        custom: null,
      },
      lists: [
        {
          value: 'hr',
          text: 'HR',
        },
        {
          value: 'en',
          text: 'EN',
        },
        {
          value: 'all',
          text: 'All',
        },
      ],
      roles: [
        {
          value: 'freelancer',
          text: 'Freelancers',
        },
        {
          value: 'client',
          text: 'Clients',
        },
        {
          value: 'administrator',
          text: 'Admins',
        },
        {
          value: 'custom',
          text: 'Custom',
        },
        {
          value: 'all',
          text: 'All',
        },
      ],
    };
  },
  mounted() {
    this.getNewsletter();
  },
  methods: {
    async send() {
      try {
        await ApiService.post(`/newsletters/${this.$route.params.newsletterId}/send`, this.form);
        this.$bvToast.toast('Newsletter successfully sent', {
          title: 'Newsletter sent',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Something went wrong, check console for log', {
          title: 'Newsletter NOT sent',
          variant: 'danger',
        });
      }
    },
    async getNewsletter() {
      const res = await ApiService.get(`/newsletters/${this.$route.params.newsletterId}`);
      this.newsletter = res.data.data;
    },
  },
  computed: {
    title() {
      return `Send Newsletter - ${this.newsletter ? this.newsletter.name : ''}`;
    },
  },
};
</script>
