export default {
  computed: {
    taskTypes() {
      return [
        {
          value: 'fulltime',
          text: 'Full Time',
        },
        {
          value: 'parttime',
          text: 'Part Time',
        },
        {
          value: 'onetime',
          text: 'One Time',
        },
      ];
    },
    taskLocations() {
      return [
        {
          value: 'onsite',
          text: 'On Site',
        },
        {
          value: 'remote',
          text: 'Remote',
        },
        {
          value: 'hybrid',
          text: 'Hybrid',
        },
      ];
    },
    taskStatuses() {
      return [
        {
          value: 0,
          text: 'Created',
        },
        {
          value: 1,
          text: 'Filled',
        },
        {
          value: 2,
          text: 'Accepting applications',
        },
      ];
    },
    escrowStatuses() {
      return {
        0: 'No escrow',
        1: 'Escrow pending',
        2: 'Escrow Set',
        3: 'Escrow Set for Payment',
        4: 'Escrow Paid',
        5: 'Escrow Set for Withdrawal',
        6: 'Escrow Withdrawn',
        7: 'Escrow in dispute by client',
        8: 'Escrow in dispute by freelancer',
      };
    },
    escrowStatusesSelect() {
      return Object.keys(this.escrowStatuses)
        .map((s) => ({
          value: s,
          text: this.escrowStatuses[s],
        }));
    },
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        value: l.id,
        text: l.name,
      }));
    },

    currencies() {
      return this.$store.state.util.currencies.map((l) => ({
        value: l.id,
        text: l.code,
      }));
    },

    priceTypeOptions() {
      if (this.task.type === 'parttime' || this.task.type === 'fulltime') {
        return [
          {
            value: 'hourly',
            text: 'per hour',
          },
          {
            value: 'monthly',
            text: 'per month',
          },
          {
            value: 'yearly',
            text: 'per year',
          },
        ];
      }
      if (this.task.type === 'onetime') {
        return [
          {
            value: 'hourly',
            text: 'per hour',
          },
          {
            value: 'fixed',
            text: 'per project',
          },
        ];
      }

      return [];
    },
  },
  methods: {
    changeCategories(e) {
      this.task = {
        ...this.task,
        categories: e,
      };
    },

    changeSkills(e) {
      this.task = {
        ...this.task,
        skills: e,
      };
    },
  },
};
