<template>
  <div>
    <b-button-group class="mb-3">
      <b-button to="/templates/create">
        Create new template
      </b-button>
    </b-button-group>
    <div class="row">
      <div class="col-12">
        <b-card header="Templates" header-bg-variant="primary">
          <b-table striped :items="items" :fields="fields" id="my-table">
            <template v-slot:cell(name)="data">
              <router-link :to="`/templates/${data.item.id}`">{{data.item.name}}</router-link>
            </template>
            <template v-slot:cell(createdAt)="data">
              {{new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss')}}
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'TemplatesList',
  data() {
    return {
      items: [],
      fields: ['id', 'name', 'createdAt'],
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      const res = await ApiService.get('/templates');
      this.items = res.data.data;
    },
  },
};
</script>

<style scoped>

</style>
