<template>
  <b-card header-bg-variant="success" header="Invite">
    <b-overlay :show="inviting">
      <b-form @submit.prevent="invite">
        <b-form-group label="Select task" :state="$v.form.task.$error ? false : null">
          <v-select v-model="form.task" :filterable="false" :options="options"
                    @search="fetchTasks"/>
        </b-form-group>

        <b-form-group label="Letter" :state="$v.form.letter.$error ? false : null">
          <b-form-textarea v-model="form.letter" rows="3"
                           :state="$v.form.letter.$error ? false : null"/>
        </b-form-group>

        <b-button variant="success" type="submit" :disabled="inviting">Invite</b-button>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { required } from 'vuelidate/lib/validators';
import ApiService from '../../services/ApiService';

export default {
  name: 'FreelancerInvite',
  components: {
    vSelect,
  },
  props: {
    freelancer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        task: null,
        letter: null,
      },
      inviting: false,
      options: [],
    };
  },
  validations: {
    form: {
      task: {
        required,
      },
      letter: {
        required,
      },
    },
  },
  methods: {
    async fetchTasks(search, loading) {
      if (search) {
        loading(true);
        const { data } = await ApiService.get('/tasks', {
          params: {
            q: search,
          },
        });
        this.options = data.data.hits.map((i) => ({
          // eslint-disable-next-line no-underscore-dangle
          label: `${i._id} - ${i._source.title}`,
          // eslint-disable-next-line no-underscore-dangle
          id: i._id,
        }));
        loading(false);
      }
    },
    async invite() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.inviting = true;
        try {
          const data = {
            freelancerId: this.freelancer.id,
            taskId: this.form.task.id,
            letter: this.form.letter,
          };
          await ApiService.post('/invitations', data);
          this.$bvToast.toast('Invitation successfully created', {
            title: 'Invitation created',
            variant: 'success',
          });
          this.form.task = null;
          this.form.letter = null;
          this.$v.$reset();
          this.inviting = false;
        } catch (err) {
          this.inviting = false;
          this.$bvToast.toast(err.response?.data?.error, {
            title: 'ERROR',
            variant: 'danger',
          });
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
