<template>
  <b-card header-bg-variant="info" header="Projects" body-class="p-0">
    <freelancer-project v-for="p in freelancer.projects" :key="p.id" :project="p"/>
    <p class="p-3 m-0" v-if="empty">
      No projects added
    </p>
  </b-card>
</template>

<script>
import FreelancerProject from './FreelancerProject.vue';

export default {
  name: 'FreelancerProjects',
  components: { FreelancerProject },
  props: {
    freelancer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    empty() {
      return !this.freelancer?.projects?.length;
    },
  },
};
</script>
