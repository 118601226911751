<template>
  <div class="row">
    <div class="col-8">
      <b-card header-bg-variant="primary" :header="task.title">
        <b-form @submit.prevent="save">
          <b-form-group id="languageId" label="Language">
            <b-form-select v-model="task.languageId" :options="languages"/>
          </b-form-group>

          <b-form-group id="title" label="Title">
            <b-form-input v-model="task.title"/>
          </b-form-group>

          <b-form-group id="description" label="Description">
            <wysiwyg-editor v-model="task.description"/>
          </b-form-group>

          <b-form-group id="type" label="Type">
            <b-form-select v-model="task.type" :options="taskTypes"/>
          </b-form-group>

          <b-form-group id="price" label="Price">
            <b-form-input v-model="task.price"/>
          </b-form-group>

          <b-form-group id="currencyId" label="Currency">
            <b-form-select v-model="task.currencyId" :options="currencies"/>
          </b-form-group>

          <b-form-group id="priceType" label="Price Type">
            <b-form-select v-model="task.priceType" :options="priceTypeOptions"/>
          </b-form-group>

          <b-form-group id="negotiablePrice" label="Negotiable price">
            <b-form-checkbox v-model="task.negotiablePrice"/>
          </b-form-group>

          <b-form-group id="duration" label="Duration">
            <b-form-input v-model="task.duration"/>
          </b-form-group>

          <b-form-group id="negotiableDuration" label="Negotiable duration">
            <b-form-checkbox v-model="task.negotiableDuration"/>
          </b-form-group>

          <b-form-group id="location" label="Location">
            <b-form-select v-model="task.location" :options="taskLocations"/>
          </b-form-group>

          <b-form-group id="status" label="Status">
            <b-form-select v-model="task.status" :options="taskStatuses"/>
          </b-form-group>

          <b-form-group id="featured" label="Featured">
            <b-form-checkbox v-model="task.featured"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
    <div class="col-4">
      <b-card header-bg-variant="success" header="Created by">
        <v-select
          :options="clients"
          :value="task.client"
          :multiple="false"
          label="name"
          @input="setClient"
          @search="findClient"
          @search:focus="findClient"
        >
        </v-select>
      </b-card>

      <task-skills :task="task" @input-categories="changeCategories" @input-skills="changeSkills"/>

      <b-card header-bg-variant="info" header="External Details">
        <b-form-group id="externalUrl" label="URL">
          <b-form-input v-model="task.externalUrl"/>
        </b-form-group>
      </b-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import TaskSkills from '@/components/task/TaskSkills.vue';
import ApiService from '../../services/ApiService';
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import TaskDetails from '../../mixins/TaskDetails';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'CreateTask',
  components: {
    TaskSkills,
    WysiwygEditor,
    vSelect,
  },
  mixins: [TaskDetails],
  data() {
    return {
      task: {
        type: 'onetime',
        status: 0,
        featured: true,
      },
      refreshingBalance: false,
      clients: [],
    };
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        value: l.id,
        text: l.name,
      }));
    },

    currencies() {
      return this.$store.state.util.currencies.map((l) => ({
        value: l.id,
        text: l.code,
      }));
    },

    priceTypeOptions() {
      if (this.task.type === 'parttime' || this.task.type === 'fulltime') {
        return [
          {
            value: 'hourly',
            text: 'per hour',
          },
          {
            value: 'monthly',
            text: 'per month',
          },
          {
            value: 'yearly',
            text: 'per year',
          },
        ];
      }
      if (this.task.type === 'onetime') {
        return [
          {
            value: 'hourly',
            text: 'per hour',
          },
          {
            value: 'fixed',
            text: 'per project',
          },
        ];
      }

      return [];
    },
  },
  methods: {
    async save() {
      try {
        const res = await ApiService.post('/tasks', this.task);
        this.$bvToast.toast('Task successfully saved', {
          title: 'Task saved',
          variant: 'success',
        });
        await this.$router.push(`/tasks/${res.data.data.id}`);
      } catch (err) {
        this.$bvToast.toast('Task NOT saved', {
          title: 'ERROR',
          variant: 'danger',
        });
      }
    },
    setClient(e) {
      this.task.postedBy = e ? e.id : null;
      this.task.owner = e;
    },
    async findClient(e) {
      const res = await ApiService.get(`/clients?page=1&perPage=10&q=${e || ''}`);
      // eslint-disable-next-line no-underscore-dangle
      this.clients = res.data.data.hits.map((h) => ({ id: h._id, ...h._source }));
    },
  },
};
</script>
