<template>
  <b-badge v-if="badge" :variant="badge.variant">{{ badge.label }}</b-badge>
</template>

<script>
import { escrowStatuses } from 'ct-constants';

export default {
  name: 'EscrowStatusBadge',
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badge() {
      switch (this.task?.escrowStatus) {
        case escrowStatuses.NO_ESCROW:
          return {
            variant: 'danger',
            label: 'No escrow',
          };
        case escrowStatuses.ESCROW_PENDING:
          return {
            variant: 'danger',
            label: 'Pending',
          };
        case escrowStatuses.ESCROW_SET:
          return {
            variant: 'success',
            label: 'Set',
          };
        case escrowStatuses.ESCROW_PAYMENT:
          return {
            variant: 'warning',
            label: 'Payment',
          };
        case escrowStatuses.ESCROW_PAID:
          return {
            variant: 'warning',
            label: 'Paid',
          };
        case escrowStatuses.ESCROW_WITHDRAWAL:
          return {
            variant: 'info',
            label: 'Withdrawal',
          };
        case escrowStatuses.ESCROW_WITHDRAWN:
          return {
            variant: 'info',
            label: 'Withdrawn',
          };
        case escrowStatuses.ESCROW_DISPUTE_CLIENT:
        case escrowStatuses.ESCROW_DISPUTE_FREELANCER:
          return {
            variant: 'danger',
            label: 'Dispute',
          };
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>

</style>
