<template>
  <div class="row">
    <div class="col-12">
      <b-button to="/tasks/create" variant="primary" class="mb-4">Create new task</b-button>
      <tasks-search-form @search="search"/>
      <published-stats v-if="statisticsData" :statistics="statistics"/>
      <b-card header="Tasks" header-bg-variant="primary">
        <b-table striped :items="tasksProvider" :fields="fields" :per-page="perPage"
                 :current-page="currentPage" id="my-table" :filter="filter">
          <template v-slot:cell(title)="data">
            <router-link :to="`/tasks/${data.item.id}`">{{ data.item.title }}</router-link>
          </template>
          <template v-slot:cell(postedBy)="data">
            <router-link :to="`/clients/${data.item.postedById}`">
              {{ data.item.postedBy || data.item.postedById }}
            </router-link>
          </template>
          <template v-slot:cell(published)="data">
            <status-badge :status="data.item.published"/>
          </template>
          <template v-slot:cell(confirmed)="data">
            <status-badge :status="data.item.confirmed" true-label="Confirmed"
                          false-label="Unconfirmed"/>
          </template>
          <template v-slot:cell(agencyOn)="data">
            <b-badge :variant="data.item.agencyOn ? 'success' : 'danger'">
              {{ data.item.agencyOn }}
            </b-badge>
          </template>
          <template v-slot:cell(escrowStatus)="data">
            <escrow-status-badge :task="data.item" />
          </template>
          <template v-slot:cell(timePosted)="data">
            {{ new Date(data.item.timePosted) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
          </template>
        </b-table>

        <template v-slot:footer class="clearfix">
          <b-pagination
            class="m-0 float-right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"/>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import StatusBadge from '../../components/StatusBadge.vue';
import PublishedStats from '../../components/ui/PublishedStats.vue';
import TasksSearchForm from '../../components/task/TasksSearchForm.vue';
import EscrowStatusBadge from '../../components/task/EscrowStatusBadge.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'TasksSearch',
  components: {
    EscrowStatusBadge,
    TasksSearchForm,
    PublishedStats,
    StatusBadge,
  },
  data() {
    return {
      fields: ['title', 'postedBy', 'language', 'published', 'confirmed', 'agencyOn', 'timePosted', 'escrowStatus'],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      filter: null,
      statisticsData: null,
    };
  },
  methods: {
    search(e) {
      this.filter = { ...e };
    },
    tasksProvider(ctx) {
      const qs = [];

      const filter = { ...(ctx.filter ? ctx.filter : []) };

      if (!filter.languages) {
        filter.languages = 'all';
      }

      Object.keys(filter)
        .forEach((f) => {
          if (filter[f] !== undefined && filter[f] !== null) {
            qs.push(`${f}=${filter[f]}`);
          }
        });

      qs.push(`page=${ctx.currentPage}`);
      qs.push(`perPage=${ctx.perPage}`);

      return ApiService.get(`/tasks?${qs.join('&')}`)
        .then((res) => {
          // eslint-disable-next-line no-underscore-dangle
          const tasks = res.data.data.hits.map((h) => ({ id: h._id, ...h._source }));

          this.rows = res.data.data.total.value;

          return tasks || [];
        });
    },

    async getStatistics() {
      const res = await ApiService.get('/statistics/tasks');
      this.statisticsData = res.data.data;
    },
  },
  computed: {
    statistics() {
      const languages = this.$store.getters['util/getEnabledLanguagesMap'];
      if (languages) {
        return this.statisticsData.map((s) => ({
          ...s,
          unpublished: s.total - s.published,
          language: languages[s.languageId],
        }));
      }
      return [];
    },
  },
  mounted() {
    this.getStatistics();
  },
};
</script>
