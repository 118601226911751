<template>
  <b-card header-bg-variant="success" header="User data">
    <dl class="row mb-3">
      <dt class="col-sm-3">ID</dt>
      <dd class="col-sm-9">{{user.id}}</dd>

      <dt class="col-sm-3">Email</dt>
      <dd class="col-sm-9">
        <router-link :to="`/users/${user.id}`">{{user.email}}</router-link>
      </dd>

      <dt class="col-sm-3">Active</dt>
      <dd class="col-sm-9">{{user.active}}</dd>

      <dt class="col-sm-3">Affiliate ID</dt>
      <dd class="col-sm-9">{{user.referrerId}}</dd>
    </dl>

    <toggle-user-buttons :user="user" @toggled="toggleUser"/>
  </b-card>
</template>

<script>
import ToggleUserButtons from './ToggleUserButtons.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'UserInfoCard',
  components: { ToggleUserButtons },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    toggleUser() {
      this.$emit('toggled');
    },
  },
};
</script>

<style scoped>

</style>
