import { keyBy, orderBy } from 'lodash/collection';
import ApiService from '../services/ApiService';
import extract from '../libs/extractLanguage';

const initialState = {
  languages: [],
  currencies: [],
  categories: [],
  paymentMethods: [],
  countries: [],
};

const actions = {
  async getLanguages({ commit }) {
    const res = await ApiService.get('/utils/languages');
    commit('setLanguages', res.data.data);
  },

  async getCurrencies({ commit }) {
    const res = await ApiService.get('/utils/currencies');
    commit('setCurrencies', res.data.data);
  },

  async getCategories({ commit }) {
    const res = await ApiService.get('/utils/categories');
    commit('setCategories', res.data.data);
  },

  async getPaymentMethods({ commit }) {
    const res = await ApiService.get('/payment-methods');
    commit('setPaymentMethods', res.data.data);
  },

  async getCountries({ commit }) {
    const res = await ApiService.get('/utils/countries');
    commit('setCountries', res.data.data);
  },
};

const mutations = {
  setLanguages(state, languages) {
    state.languages = languages;
  },

  setCountries(state, countries) {
    state.countries = countries;
  },

  setCurrencies(state, currencies) {
    state.currencies = currencies;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },

  setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
};

const g = {
  getEnabledLanguages(state) {
    return state.languages.filter((l) => l.enabled);
  },

  getEnabledLanguagesMap(state, getters) {
    return keyBy(getters.getEnabledLanguages, 'id');
  },

  getDefaultLanguage(state, getters) {
    return getters.getEnabledLanguages.find((l) => l.code === 'en');
  },

  getAllSkills(state) {
    const skills = [];
    state.categories.forEach((c) => skills.push(...c.skills));
    return skills;
  },

  getCategoryTranslations(state, getters) {
    return (orderBy(extract(state.categories, getters.getDefaultLanguage?.id), 'name')).map(({
      id,
      name,
    }) => ({
      id,
      name,
    }));
  },

  getSkillTranslations(state, getters) {
    return (orderBy(extract(getters.getAllSkills, getters.getDefaultLanguage?.id), 'name')).map(({
      id,
      name,
      categoryId,
    }) => ({
      id,
      name,
      categoryId,
    }));
  },

  getSkillsByCategories: (state, getters) => (categoryIds) => {
    const skills = getters.getAllSkills.filter((s) => categoryIds.includes(s.categoryId));

    if (skills.length) {
      return (orderBy(
        extract(skills, getters.getDefaultLanguage.id),
        'name',
        'asc',
      )).map(({
        id,
        name,
        categoryId,
      }) => ({
        id,
        name,
        categoryId,
      }));
    }

    return [];
  },
  getCurrenciesById(state) {
    return keyBy(state.currencies, 'id');
  },

  getPaymentMethodsById(state) {
    return keyBy(state.paymentMethods, 'id');
  },
};

const store = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: g,
};

export default store;
