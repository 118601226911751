<template>
  <div class="row">
    <div class="col-8">
      <b-card header-bg-variant="primary" :header="client.name">
        <b-form @submit.prevent="save">
          <b-form-group id="name" label="Name">
            <b-form-input v-model="client.name"/>
          </b-form-group>

          <b-form-group id="countryId" label="Country">
            <b-form-select v-model="client.countryId" :options="countries"/>
          </b-form-group>

          <b-form-group id="location" label="Location">
            <b-form-input v-model="client.location"/>
          </b-form-group>

          <b-form-group id="resume" label="Resume">
            <wysiwyg-editor v-model="client.about"/>
          </b-form-group>

          <b-form-group id="feedSource" label="Feed Source">
            <b-form-input v-model="client.feedSource"/>
          </b-form-group>

          <b-form-group id="trusted" label="Trusted client">
            <b-form-checkbox v-model="client.trusted"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
    <div class="col-4">
      <b-card header-bg-variant="info" header="Info">
        <dl class="row mb-0">
          <dt class="col-sm-3">Id</dt>
          <dd class="col-sm-9">{{ client.id }}</dd>

          <dt class="col-sm-3">Created at</dt>
          <dd class="col-sm-9">{{ client.createdAt }}</dd>

          <dt class="col-sm-3">Updated at</dt>
          <dd class="col-sm-9">{{ client.updatedAt }}</dd>
        </dl>
      </b-card>

      <user-info-card :user="client.user" @toggled="userToggled"/>

      <b-card header-bg-variant="warning" header="Avatar">
        <div class="d-flex align-items-center justify-content-center">
          <image-uploader label="Avatar" title="Avatar" :value="client.avatar"
                          :croppable="true" @input="imageAdded" @remove="imageRemoved"/>
        </div>
      </b-card>

      <b-card header-bg-variant="success" header="Tasks" body-class="p-0">
        <small-tasks-list :tasks="tasks"/>
      </b-card>

      <client-subscriptions :client-id="$route.params.clientId"/>
    </div>
  </div>
</template>

<script>
import set from 'lodash/set';
import UserInfoCard from '../../components/user/UserInfoCard.vue';
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import ApiService from '../../services/ApiService';
import ImageUploader from '../../components/form/ImageUploader.vue';
import SmallTasksList from '../../components/task/SmallTasksList.vue';
import ClientSubscriptions from '../../components/user/ClientSubscriptions.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Client',
  components: {
    ClientSubscriptions,
    SmallTasksList,
    ImageUploader,
    WysiwygEditor,
    UserInfoCard,
  },
  data() {
    return {
      client: {},
      tasks: [],
    };
  },
  methods: {
    userToggled() {
      set(this, 'client.user.active', !this.client.user.active);
    },

    async save() {
      try {
        await ApiService.put(`/clients/${this.client.id}`, this.client);
        this.$bvToast.toast('Client successfully saved', {
          title: 'Client saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Client NOT saved', {
          title: 'ERROR',
          variant: 'danger',
        });
      }
    },

    imageAdded(data) {
      this.client.avatar = data;
      this.client.pictureId = data.id;
    },

    imageRemoved() {
      this.client.avatar = null;
      this.client.pictureId = null;
    },

    async getTasks() {
      const { clientId } = this.$route.params;
      const { data } = await ApiService.get(`/clients/${clientId}/tasks`);
      this.tasks = data.data;
    },

    async getClient() {
      const { clientId } = this.$route.params;
      const { data } = await ApiService.get(`/clients/${clientId}`);
      this.client = data.data;
    },
  },
  computed: {
    user() {
      return this.client.user || {};
    },

    countries() {
      return this.$store.state.util.countries.map((c) => ({
        value: c.id,
        text: c.name,
      }));
    },
  },
  created() {
    this.getClient();
    this.getTasks();
  },
};
</script>

<style scoped>

</style>
