<template>
  <b-list-group flush>
    <b-list-group-item v-for="task in tasks" :key="task.id" :to="`/tasks/${task.id}`"
                       class="flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between mb-2">
        <h5 class="m-0 d-flex align-items-center">
          {{ task.title }}
        </h5>
        <small class="d-flex align-items-center">{{
            new Date(task.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss')
          }}</small>
      </div>
      <div class="d-flex align-items-center">
        <status-badge :status="task.confirmed" true-label="Confirmed"
                      false-label="Unconfirmed" class="mr-3"/>
        <status-badge :status="task.published" class="mr-3"/>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import StatusBadge from '../StatusBadge.vue';

export default {
  name: 'SmallTasksList',
  components: { StatusBadge },
  props: {
    tasks: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped>

</style>
