<template>
  <div>
    <b-button variant="danger" v-if="confirmed"
              @click.prevent="freelancerToggle">
      Unconfirm
    </b-button>
    <b-button variant="success" v-if="!confirmed"
              @click.prevent="freelancerToggle">
      Confirm
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationToggleButton',
  props: {
    confirmed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    freelancerToggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style scoped>

</style>
