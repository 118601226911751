<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-card header-bg-variant="primary" header="Create new client user">
          <b-form @submit.prevent="save">
            <b-form-group id="email" label="Email">
              <b-form-input v-model="form.email"/>
            </b-form-group>

            <b-form-group id="password" label="Password">
              <b-form-input v-model="form.password"/>
            </b-form-group>

            <b-form-group id="type" label="Language">
              <b-form-select v-model="form.languageId" :options="languages"/>
            </b-form-group>

            <b-button type="submit" variant="primary">Save</b-button>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'CreateClient',
  data() {
    return {
      form: {
        email: null,
        password: null,
        languageId: null,
      },
    };
  },
  methods: {
    async save() {
      try {
        const data = {
          ...this.form,
          role: 'client',
        };

        const res = await ApiService.post('/users', data);
        await this.$router.replace(`/clients/${res.data.data.client.id}`);
        this.$bvToast.toast('Client successfully saved', {
          title: 'Client saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Client NOT saved', {
          title: 'ERROR',
          variant: 'danger',
        });
      }
    },
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        text: l.name,
        value: l.id,
      }));
    },
  },
};
</script>

<style scoped>

</style>
