<template>
  <b-card header-bg-variant="warning" header="Applications" body-class="p-0">
    <b-list-group flush>
      <template v-for="app in applications">
        <b-list-group-item :to="`/applications/${app.id}`" :key="app.id"
                           class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 d-flex align-items-center">
              {{ app.freelancer.name }}
              <application-status-badge class="ml-3" :application="app" />
            </h5>
            <small>{{ new Date(app.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}</small>
          </div>

          <p class="mb-0">
            Freelancer visibility: <strong>{{ app.freelancer.visibility }}</strong> |
            Recommended: <strong>{{ app.recommended }}</strong>
          </p>
        </b-list-group-item>
      </template>
    </b-list-group>
    <p class="p-3 m-0" v-if="!applications.length">
      No applications for this task
    </p>
  </b-card>
</template>

<script>
import ApplicationStatusBadge from '../applications/ApplicationStatusBadge.vue';

export default {
  name: 'TaskApplications',
  components: { ApplicationStatusBadge },
  props: {
    applications: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped>

</style>
