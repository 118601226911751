<template>
  <div class="wrapper">
    <!-- Navbar -->
    <top-navbar/>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <div class="brand brand-link d-flex align-items-center justify-content-between px-3">
        <!-- Brand Logo -->
        <span class="brand-text font-weight-light">CryptoTask Admin</span>

        <a href="#" @click.prevent="toggleSidebar" class="text-white d-lg-none">
          <font-awesome-icon icon="times"/>
        </a>
      </div>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <main-menu/>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>

    <slot/>

    <!-- Main Footer -->
    <footer class="main-footer">
      <!-- Default to the left -->
      <strong>CryptoTask 2021</strong>
    </footer>
  </div>
  <!-- ./wrapper -->
</template>

<script>
import MainMenu from '../components/ui/MainMenu.vue';
import TopNavbar from '../components/ui/TopNavbar.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'MainLayout',
  components: {
    TopNavbar,
    MainMenu,
  },
  methods: {
    toggleSidebar() {
      this.$store.commit('ui/toggleSidebar');
    },
  },
};
</script>

<style scoped>

</style>
