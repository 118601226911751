<template>
  <standard-wrapper>
    <template #title>Leads</template>
    <router-view/>
  </standard-wrapper>
</template>

<script>
import StandardWrapper from '../components/wrappers/StandardWrapper.vue';

export default {
  name: 'Leads',
  components: { StandardWrapper },
};
</script>
