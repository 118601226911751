<template>
  <a @click.prevent="logout" href="#">
    Logout
  </a>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Logout',
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
