<template>
  <b-form @submit.prevent="search">
    <b-input-group class="mb-4">
      <b-form-input
        v-model="form.q"
        type="text"
        placeholder="Enter search term"/>
      <b-input-group-append>
        <b-button variant="danger" @click.prevent="clear">Clear</b-button>
      </b-input-group-append>
      <b-input-group-append>
        <b-button variant="info" v-b-toggle.collapse-1>Advanced</b-button>
      </b-input-group-append>
      <b-input-group-append>
        <b-button variant="success" type="submit">Search</b-button>
      </b-input-group-append>
    </b-input-group>

    <b-collapse id="collapse-1" class="mb-4">
      <b-card>
        <div class="row">
          <div class="col-md-3">
            <b-form-group label="Language">
              <b-form-select v-model="form.languages" :options="languages"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="Escrow Status">
              <b-form-select v-model="form.escrowStatus" :options="escrowStatuses"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="Task Status">
              <b-form-select v-model="form.status" :options="taskStatuses"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group id="agencyOn" label="Agency enabled only">
              <b-form-checkbox v-model="form.agencyOn" :unchecked-value="null" />
            </b-form-group>
          </div>
        </div>
      </b-card>
    </b-collapse>
  </b-form>
</template>

<script>
export default {
  name: 'TasksSearchForm',
  data() {
    return {
      form: {
        q: '',
        escrowStatus: null,
        status: null,
        languages: 'all',
        agencyOn: null,
      },
      escrowStatuses: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'All Escrow',
          value: 'all',
        },
        {
          text: 'No Escrow',
          value: 0,
        },
        {
          text: 'Escrow pending',
          value: 1,
        },
        {
          text: 'Escrow Set',
          value: 2,
        },
        {
          text: 'Payment pending',
          value: 3,
        },
        {
          text: 'Paid',
          value: 4,
        },
        {
          text: 'Set for withdrawal',
          value: 5,
        },
        {
          text: 'Withdrawn',
          value: 6,
        },
        {
          text: 'Client dispute',
          value: 7,
        },
        {
          text: 'Freelancer dispute',
          value: 8,
        },
      ],
      taskStatuses: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Created',
          value: 0,
        },
        {
          text: 'Filled',
          value: 1,
        },
        {
          text: 'Accepting',
          value: 2,
        },
        {
          text: 'Closed',
          value: 9,
        },
      ],
    };
  },
  methods: {
    clear() {
      this.form.q = '';
      this.search();
    },
    search() {
      this.$emit('search', this.form);
    },
  },
  computed: {
    languages() {
      const enabledLangs = this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        text: l.name,
        value: l.id,
      }));
      const ls = [
        { text: 'All', value: 'all' },
      ];
      ls.push(...enabledLangs);
      return ls;
    },
  },
};
</script>
