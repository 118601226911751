<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="Lead">
        <b-form v-if="lead" @submit.prevent="save">
          <b-form-group id="pageId" label="Page ID">
            <b-form-input v-model="lead.pageId"/>
          </b-form-group>

          <b-form-group id="pageName" label="Page Name">
            <b-form-input v-model="lead.pageName"/>
          </b-form-group>

          <b-form-group id="pageUrl" label="Page URL">
            <b-form-input v-model="lead.pageUrl"/>
          </b-form-group>

          <b-form-group id="variant" label="variant">
            <b-form-input v-model="lead.variant"/>
          </b-form-group>

          <b-form-group id="name" label="Lead Name">
            <b-form-input v-model="lead.name"/>
          </b-form-group>

          <b-form-group id="email" label="Lead Email">
            <b-form-input v-model="lead.email"/>
          </b-form-group>

          <b-form-group id="phone" label="Lead Phone">
            <b-form-input v-model="lead.phone"/>
          </b-form-group>

          <b-form-group id="website" label="Lead Website">
            <b-form-input v-model="lead.website"/>
          </b-form-group>

          <b-form-checkbox
            id="subscribed"
            v-model="lead.subscribed"
            name="subscribed"
            class="mb-4"
          >
            Subscribed
          </b-form-checkbox>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'Lead',
  data() {
    return {
      lead: null,
    };
  },
  mounted() {
    this.loadLead();
  },
  methods: {
    async loadLead() {
      const id = this.$route.params.leadId;
      const { data } = await ApiService.get(`/leads/${id}`);
      this.lead = data.data;
    },
    async save() {
      try {
        const id = this.$route.params.leadId;
        const { data } = await ApiService.put(`/leads/${id}`, this.lead);
        this.lead = data.data;
        this.$bvToast.toast('Lead successfully saved', {
          title: 'Lead saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('Lead NOT saved', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
