<template>
  <b-card header-bg-variant="info" header="Subscriptions" body-class="p-0">
    <subscriptions-small-list v-if="subscriptions.length" :subscriptions="subscriptions" />
    <p class="p-3 m-0" v-if="!subscriptions.length">
      No subscriptions for this client
    </p>
  </b-card>
</template>

<script>
import SubscriptionsSmallList from '../subscriptions/SubscriptionsSmallList.vue';
import ApiService from '../../services/ApiService';

export default {
  name: 'ClientSubscriptions',
  components: { SubscriptionsSmallList },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      subscriptions: [],
    };
  },
  async mounted() {
    const { data } = await ApiService.get(`/clients/${this.clientId}/subscriptions`);
    this.subscriptions = data.data;
  },
};
</script>

<style scoped>

</style>
