<template>
  <div v-if="subscription" class="row">
    <div class="col-8">
      <b-card header-bg-variant="primary" :header="subscription.Package.name">
        <b-form @submit.prevent="save">
          <b-form-group id="qty" label="Quantity">
            <b-form-input v-model="subscription.qty"/>
          </b-form-group>

          <b-form-group id="unit" label="Unit">
            <b-form-select v-model="subscription.unit" :options="units"/>
          </b-form-group>

          <b-form-group id="price" label="Price">
            <b-form-input v-model="subscription.price"/>
          </b-form-group>

          <b-form-group id="currencyId" label="Currency">
            <b-form-select v-model="subscription.currencyId" :options="currencies"/>
          </b-form-group>

          <b-form-group id="status" label="Status">
            <b-form-select v-model="subscription.status" :options="statuses"/>
          </b-form-group>

          <b-form-group id="from" label="From">
            <b-form-input v-model="subscription.from"/>
          </b-form-group>

          <b-form-group id="to" label="To">
            <b-form-input v-model="subscription.to"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
    <div class="col-4">
      <b-card header-bg-variant="info" header="Info">
        <dl class="row mb-0">
          <dt class="col-sm-3">Created</dt>
          <dd class="col-sm-9">{{
              new Date(subscription.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss')
            }}
          </dd>

          <dt class="col-sm-3">Updated</dt>
          <dd class="col-sm-9">{{
              new Date(subscription.updatedAt) | dateFormat('DD.MM.YYYY HH:mm:ss')
            }}
          </dd>

          <dt class="col-sm-3">Created By</dt>
          <dd class="col-sm-9">
            <router-link :to="`/users/${subscription.userId}`">{{ subscription.userId }}
            </router-link>
          </dd>
        </dl>

        <strong>Subscription options:</strong>
        <div class="bg-light">
          <pre class="mb-0">{{ subscription.options }}</pre>
        </div>
      </b-card>

      <b-card header-bg-variant="warning" header="Package Info">
        <dl class="row mb-0">
          <dt class="col-sm-3">Title</dt>
          <dd class="col-sm-9">{{ subscription.Package.title }}</dd>

          <dt class="col-sm-3">Unique Name</dt>
          <dd class="col-sm-9">{{ subscription.Package.name }}</dd>

          <dt class="col-sm-3">Resolver</dt>
          <dd class="col-sm-9">{{ subscription.Package.resolver }}</dd>
        </dl>
      </b-card>

      <b-card header-bg-variant="success" header="Current Payment Info">
        <dl v-if="subscription.Payment" class="row mb-0">
          <dt class="col-sm-3">ID</dt>
          <dd class="col-sm-9">{{ subscription.Payment.id }}</dd>

          <dt class="col-sm-3">Hash code</dt>
          <dd class="col-sm-9"><strong>{{ subscription.Payment.hash }}</strong></dd>

          <dt class="col-sm-3">Title</dt>
          <dd class="col-sm-9">
            <router-link :to="`/payments/${subscription.Payment.id}`">
              {{ subscription.Payment.title }}
            </router-link>
          </dd>

          <dt class="col-sm-3">Amount</dt>
          <dd class="col-sm-9">
            <price :amount="subscription.Payment.amount"
                   :currency-id="subscription.Payment.currencyId"/>
          </dd>

          <dt class="col-sm-3">Description</dt>
          <dd class="col-sm-9">{{ subscription.Payment.description }}</dd>

          <dt class="col-sm-3">Status</dt>
          <dd class="col-sm-9">
            <payment-status-badge :status="subscription.Payment.status"/>
          </dd>

          <dt class="col-sm-3">Payment Method</dt>
          <dd class="col-sm-9">
            {{ paymentMethod ? paymentMethod.title : '' }}
          </dd>

          <dt class="col-sm-3">Created</dt>
          <dd class="col-sm-9">{{ subscription.Payment.createdAt }}</dd>

          <dt class="col-sm-3">Updated</dt>
          <dd class="col-sm-9">{{ subscription.Payment.updatedAt }}</dd>

          <dt class="col-sm-3">Created by</dt>
          <dd class="col-sm-9">
            <router-link :to="`/users/${subscription.Payment.userId}`">
              {{ subscription.Payment.userId }}
            </router-link>
          </dd>
        </dl>
        <p v-else>No payment created for this subscription</p>
      </b-card>

      <b-card header-bg-variant="primary" header="All other payments" body-class="p-0">
        <b-list-group flush>
          <payment-small-item v-for="p in otherPayments" :key="p.id" :payment="p"/>
        </b-list-group>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import Price from '../../components/ui/Price.vue';
import PaymentSmallItem from '../../components/payments/PaymentSmallItem.vue';
import PaymentStatusBadge from '../../components/payments/PaymentStatusBadge.vue';

export default {
  name: 'Subscription',
  components: {
    PaymentStatusBadge,
    PaymentSmallItem,
    Price,
  },
  data() {
    return {
      subscription: null,
      payments: [],
      saving: false,
      units: [
        {
          value: 'days',
          text: 'days',
        },
      ],
      statuses: [
        {
          value: 0,
          text: 'created',
        },
        {
          value: 1,
          text: 'payment',
        },
        {
          value: 2,
          text: 'active',
        },
        {
          value: 3,
          text: 'expired',
        },
        {
          value: 9,
          text: 'disabled',
        },
      ],
    };
  },
  async created() {
    const { data } = await ApiService.get(`/subscriptions/${this.$route.params.subscriptionId}`);
    this.subscription = data.data;

    const payments = await ApiService.get(`/subscriptions/${this.$route.params.subscriptionId}/payments`);
    this.payments = payments.data.data;
  },
  methods: {
    async save() {
      this.saving = true;

      try {
        await ApiService.put(`/subscriptions/${this.$route.params.subscriptionId}`, this.subscription);
        this.saving = false;
        this.$bvToast.toast('Subscription updated', {
          title: 'Subscription saved',
          variant: 'success',
        });
      } catch (err) {
        this.saving = false;
        this.$bvToast.toast('Subscription NOT updated', {
          title: 'Subscription NOT saved',
          variant: 'danger',
        });
      }
    },
  },
  computed: {
    currencies() {
      return this.$store.state.util.currencies.map((l) => ({
        value: l.id,
        text: l.code,
      }));
    },
    paymentMethods() {
      return this.$store.getters['util/getPaymentMethodsById'];
    },
    paymentMethod() {
      return this.paymentMethods && this.subscription.Payment
        ? this.paymentMethods[this.subscription.Payment?.paymentMethodId]
        : null;
    },
    otherPayments() {
      return this.payments.length
        ? this.payments.filter((p) => p.id !== this.subscription.Payment.id)
        : [];
    },
  },
};
</script>

<style scoped>

</style>
