<template>
  <b-badge :variant="badge.variant">{{ badge.label }}</b-badge>
</template>

<script>
export default {
  name: 'ApplicationStatusBadge',
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badge() {
      switch (this.application.status) {
        case 0:
          return {
            variant: 'info',
            label: 'Created',
          };
        case 1:
          return {
            variant: 'success',
            label: 'Accepted',
          };
        case 2:
          return {
            variant: 'warning',
            label: 'Finished',
          };
        case 3:
          return {
            variant: 'danger',
            label: 'Canceled',
          };
        case 4:
          return {
            variant: 'danger',
            label: 'Rejected',
          };
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>

</style>
