<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="Create Skill">
        <b-form @submit.prevent="save">

          <b-form-group id="category" label="Category">
            <b-form-select :options="categories" v-model="form.categoryId" />
          </b-form-group>

          <template v-for="t in form.translations">
            <category-translation-input :key="t.languageId" :translation="t" @input="updated"/>
          </template>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import CategoryTranslationInput from '../../components/categories/CategoryTranslationInput.vue';
import ApiService from '../../services/ApiService';

export default {
  name: 'SkillCreate',
  components: { CategoryTranslationInput },
  data() {
    return {
      form: {
        categoryId: null,
        translations: [],
      },
    };
  },
  methods: {
    updated(updated) {
      this.form.translations = this.form.translations.map((t) => {
        if (t.languageId === updated.languageId) {
          return updated;
        }
        return t;
      });
    },
    setTranslations() {
      this.form.translations = this.languages.map((l) => ({
        languageId: l.id,
        translation: null,
      }));
    },
    async save() {
      try {
        await ApiService.post('/skills', this.form);
        this.$bvToast.toast('Skill successfully saved', {
          title: 'Skill saved',
          variant: 'success',
        });
        await this.$router.replace(`/skills?categoryId=${this.$route.query.categoryId}`);
      } catch (err) {
        this.$bvToast.toast('Skill NOT saved', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'];
    },
    categories() {
      return this.$store.getters['util/getCategoryTranslations'].map((c) => ({
        text: c.name,
        value: c.id,
      }));
    },
  },
  mounted() {
    this.form.categoryId = this.$route.query.categoryId;
    this.setTranslations();
  },
  watch: {
    languages(n) {
      if (n && n.length) {
        this.setTranslations();
      }
    },
  },
};
</script>

<style scoped>

</style>
