<template>
  <b-card header-bg-variant="warning" header="Avatar">
    <div class="d-flex align-items-center justify-content-center">
      <avatar-image :avatar="avatar" />
    </div>
  </b-card>
</template>

<script>
import AvatarImage from './AvatarImage.vue';

export default {
  name: 'AvatarDisplay',
  components: { AvatarImage },
  props: {
    avatar: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style scoped>

</style>
