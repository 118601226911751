<template>
  <b-card header-bg-variant="warning" header="Categories & Skills">
    <input-tags label="Categories" placeholder="Categories" options-label="name" :value="categories"
                :options="allCategories" class="mb-4" :reduce="category => category.id"
                @input="changeCategories"/>
    <input-tags label="Skills" placeholder="Skills" options-label="name" :value="skills"
                :options="categorySkills" :reduce="skill => skill.id" @input="changeSkills"/>
  </b-card>
</template>

<script>
import InputTags from '../form/InputTags.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'TaskSkills',
  components: { InputTags },
  props: {
    task: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    allCategories() {
      return this.$store.getters['util/getCategoryTranslations'];
    },
    categorySkills() {
      return this.$store.getters['util/getSkillsByCategories'](this.categories);
    },
    categories() {
      return this.task?.categories?.map((c) => c.id);
    },
    skills() {
      return this.task?.skills?.map((s) => s.id);
    },
  },
  methods: {
    changeCategories(e) {
      this.$emit('input-categories', e);
    },
    changeSkills(e) {
      this.$emit('input-skills', e);
    },
  },
};
</script>

<style scoped>

</style>
