<template>
  <b-card header-bg-variant="success" header="Experiences" body-class="p-0">
    <freelancer-experience v-for="e in freelancer.workExperiences" :key="e.id" :experience="e"/>
    <p class="p-3 m-0" v-if="empty">
      No experiences added
    </p>
  </b-card>
</template>

<script>
import FreelancerExperience from './FreelancerExperience.vue';

export default {
  name: 'FreelancerExperiences',
  components: { FreelancerExperience },
  props: {
    freelancer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    empty() {
      return !this.freelancer?.workExperiences?.length;
    },
  },
};
</script>

<style scoped>

</style>
