import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.store';
import ui from './ui.store';
import util from './util.store';
import freelancers from './freelancers.store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    ui,
    util,
    freelancers,
  },
});
