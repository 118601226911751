<template>
  <b-badge :variant="variant">{{ label }}</b-badge>
</template>

<script>
export default {
  name: 'SubscriptionStatusBadge',
  data() {
    return {
      statuses: {
        0: {
          label: 'Created',
          variant: 'info',
        },
        1: {
          label: 'Payment',
          variant: 'warning',
        },
        2: {
          label: 'Active',
          variant: 'success',
        },
        3: {
          label: 'Expired',
          variant: 'danger',
        },
        9: {
          label: 'Disabled',
          variant: 'danger',
        },
      },
    };
  },
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  computed: {
    s() {
      return this.statuses[this.status];
    },
    variant() {
      return this.s?.variant || 'info';
    },
    label() {
      return this.s?.label || this.status;
    },
  },
};
</script>

<style scoped>

</style>
