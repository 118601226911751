<template>
  <router-view/>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Entry',
  created() {
    this.$store.dispatch('util/getLanguages');
    this.$store.dispatch('util/getCountries');
    this.$store.dispatch('util/getCurrencies');
    this.$store.dispatch('util/getCategories');
    this.$store.dispatch('util/getPaymentMethods');

    this.$store.dispatch('auth/init')
      .catch(() => {
        this.$router.replace('/login');
      });
  },
};
</script>
