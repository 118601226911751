<template>
  <div class="content-wrapper" @click="hideSidebar">
    <!-- Content Header (Page header) -->
    <div class="content-header" v-if="showHeader">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">
              <slot name="title"/>
            </h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <!--<ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Starter Page</li>
            </ol>-->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <slot/>
      </div><!-- /.container-fluid -->
    </section>
  </div>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'StandardWrapper',
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    hideSidebar() {
      if (!this.$screen.lg) {
        this.$store.commit('ui/hideSidebar');
      }
    },
  },
};
</script>
