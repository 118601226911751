<template>
  <div>
    <div class="row">
      <div class="col-12">
        <lead-search-form class="mb-4" @search="handleSearch" @export="handleExport"/>
        <b-card header="Leads" header-bg-variant="primary">
          <b-table striped :items="leadsProvider" :fields="fields" :per-page="perPage"
                   :current-page="currentPage" :filter="filter" id="leads-table">
            <template v-slot:cell(id)="data">
              <router-link :to="`/leads/${data.item.id}`">{{ data.item.id }}</router-link>
            </template>
            <template v-slot:cell(name)="data">
              <router-link :to="`/leads/${data.item.id}`">{{ data.item.name }}</router-link>
            </template>
            <template v-slot:cell(createdAt)="data">
              {{ new Date(data.item.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
            </template>
          </b-table>

          <template v-slot:footer class="clearfix">
            <b-pagination
              class="m-0 float-right"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="leads-table"/>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import LeadSearchForm from '../../components/leads/LeadSearchForm.vue';
import ApiService from '../../services/ApiService';

export default {
  name: 'LeadsList',
  components: { LeadSearchForm },
  data() {
    return {
      fields: ['id', 'name', 'email', 'phone', 'createdAt'],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      filter: null,
    };
  },
  methods: {
    async leadsProvider(ctx) {
      const { data } = await ApiService.get(`/leads?page=${ctx.currentPage}&term=${ctx.filter?.term || ''}&from=${ctx.filter?.from || ''}&to=${ctx.filter?.to || ''}`);
      this.rows = data.meta.total;
      return data.data;
    },
    handleSearch(filter) {
      this.filter = { ...filter };
    },
    async handleExport(filter) {
      this.filter = { ...filter };
      const { data } = await ApiService.get(`/leads?term=${filter.term || ''}&from=${filter.from || ''}&to=${filter.to || ''}&emails=true`);
      const file = new File([data.data], 'leadEmails.txt', { type: 'text/plain;charset=utf-8' });
      saveAs(file);
    },
  },
};
</script>

<style scoped>

</style>
