<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="New template data">
        <b-form @submit.prevent="save">
          <b-form-group id="name" label="Name">
            <b-form-input v-model="template.name"/>
          </b-form-group>
          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'TemplateCreate',
  data() {
    return {
      template: {
        name: '',
      },
    };
  },
  methods: {
    save() {
      ApiService.post('/templates', this.template)
        .then((res) => {
          this.$bvToast.toast('Template successfully saved', {
            title: 'Template saved',
            variant: 'success',
          });
          this.$router.push(`/templates/${res.data.data.id}`);
        });
    },
  },
};
</script>

<style scoped>

</style>
