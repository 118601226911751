<template>
  <b-list-group-item class="flex-column align-items-start">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <avatar-image :avatar="avatar" rounded="circle" :options="{width: 30, height: 30}"
                      class="mr-2"/>
        <strong>{{ sender.name }}</strong>
      </div>
      {{ new Date(message.createdAt) | dateFormat('DD.MM.YYYY HH:mm:ss') }}
    </div>
    {{ message.text }}
  </b-list-group-item>
</template>

<script>
import AvatarImage from '../user/AvatarImage.vue';

export default {
  name: 'MessageItem',
  components: { AvatarImage },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sender() {
      return this.message.sender[this.message.role];
    },
    avatar() {
      return this.sender.avatar;
    },
  },
};
</script>

<style scoped>

</style>
