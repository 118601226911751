<template>
  <grapes v-if="newsletter" :data="newsletter" @update="save"/>
</template>

<script>
import Grapes from '../../components/newsletters/Grapes.vue';
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'NewsletterBuilder',
  components: { Grapes },
  data() {
    return {
      newsletter: null,
    };
  },
  mounted() {
    ApiService.get(`/newsletters/${this.newsletterId}`)
      .then((res) => {
        this.newsletter = res.data.data;
      });
  },
  computed: {
    newsletterId() {
      return this.$route.params.newsletterId;
    },
  },
  methods: {
    async save(e) {
      const data = { ...this.newsletter, ...e };
      await ApiService.put(`/newsletters/${this.newsletterId}`, data);
      this.$bvToast.toast('Newsletter successfully saved', {
        title: 'Newsletter saved',
        variant: 'success',
      });
    },
  },
};
</script>

<style scoped>

</style>
