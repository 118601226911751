<template>
  <b-card header-bg-variant="warning" header="Default language">
    <b-form @submit.prevent="saveLanguage">
      <b-form-select v-if="freelancer && freelancer.user" :options="languages"
                     v-model="freelancer.user.languageId"
                     class="mb-3"/>
      <b-button type="submit" variant="success" :disabled="saving">{{
          saving ? 'Saving' : 'Save'
        }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'FreelancerLanguage',
  props: {
    freelancer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    async saveLanguage() {
      try {
        this.saving = true;
        await ApiService.put(`/users/${this.freelancer.userId}/language`, { languageId: this.freelancer.user.languageId });
        this.$bvToast.toast('Freelancer language successfully saved', {
          title: 'Freelancer user saved',
          variant: 'success',
        });
        this.saving = false;
      } catch (err) {
        this.saving = false;
        this.$bvToast.toast('Freelancer language not saved', {
          title: 'Freelancer not saved',
          variant: 'danger',
        });
      }
    },
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        text: l.name,
        value: l.id,
      }));
    },
  },
};
</script>
