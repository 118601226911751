import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Login from '../views/Login.vue';
import Entry from '../Entry.vue';
import Tasks from '../views/Tasks.vue';
import Task from '../views/tasks/Task.vue';
import TasksSearch from '../views/tasks/TasksSearch.vue';
import Freelancers from '../views/Freelancers.vue';
import FreelancersSearch from '../views/freelancers/FreelancersSearch.vue';
import Freelancer from '../views/freelancers/Freelancer.vue';
import Clients from '../views/Clients.vue';
import ClientsSearch from '../views/clients/ClientsSearch.vue';
import Client from '../views/clients/Client.vue';
import Users from '../views/Users.vue';
import UsersSearch from '../views/users/UsersSearch.vue';
import User from '../views/users/User.vue';
import Newsletter from '../views/Newsletter.vue';
import NewslettersList from '../views/newsletters/NewslettersList.vue';
import Templates from '../views/Templates.vue';
import TemplatesList from '../views/templates/TemplatesList.vue';
import TemplateCreate from '../views/templates/TemplateCreate.vue';
import TemplateBuilder from '../views/templates/TemplateBuilder.vue';
import NewsletterCreate from '../views/newsletters/NewsletterCreate.vue';
import NewsletterBuilder from '../views/newsletters/NewsletterBuilder.vue';
import NewsletterSend from '../views/newsletters/NewsletterSend.vue';
import NewsletterSent from '../views/newsletters/NewsletterSent.vue';
import NewsletterStats from '../views/newsletters/NewsletterStats.vue';
import Blog from '../views/Blog.vue';
import BlogPosts from '../views/blog/BlogPosts.vue';
import BlogCreate from '../views/blog/BlogCreate.vue';
import BlogEdit from '../views/blog/BlogEdit.vue';
import PagesList from '../views/pages/PagesList.vue';
import PageCreate from '../views/pages/PageCreate.vue';
import PageEdit from '../views/pages/PageEdit.vue';
import Pages from '../views/Pages.vue';
import Testimonials from '../views/Testimonials.vue';
import TestimonialList from '../views/testimonials/TestimonialList.vue';
import TestimonialsCreate from '../views/testimonials/TestimonialsCreate.vue';
import TestimonialsEdit from '../views/testimonials/TestimonialsEdit.vue';
import CreateClient from '../views/clients/CreateClient.vue';
import CreateTask from '../views/tasks/CreateTask.vue';
import Subscriptions from '../views/Subscriptions.vue';
import Payments from '../views/Payments.vue';
import Subscription from '../views/subscriptions/Subscription.vue';
import Payment from '../views/payments/Payment.vue';
import SubscriptionsList from '../views/subscriptions/SubscriptionsList.vue';
import PaymentsList from '../views/payments/PaymentsList.vue';
import Applications from '../views/Applications.vue';
import Application from '../views/applications/Application.vue';
import Leads from '../views/Leads.vue';
import LeadsList from '../views/leads/LeadsList.vue';
import Lead from '../views/leads/Lead.vue';
import Agents from '../views/Agents.vue';
import AgentsList from '../views/agents/AgentsList.vue';
import Agent from '../views/agents/Agent.vue';
import Categories from '../views/Categories.vue';
import CategoriesList from '../views/categories/CategoriesList.vue';
import CategoryCreate from '../views/categories/CategoryCreate.vue';
import CategoryEdit from '../views/categories/CategoryEdit.vue';
import Skills from '../views/Skills.vue';
import SkillList from '../views/skills/SkillList.vue';
import SkillCreate from '../views/skills/SkillCreate.vue';
import SkillEdit from '../views/skills/SkillEdit.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/',
    component: Entry,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/tasks',
        component: Tasks,
        children: [
          {
            path: '',
            name: 'TasksSearch',
            component: TasksSearch,
          },
          {
            path: 'create',
            name: 'CreateTask',
            component: CreateTask,
          },
          {
            path: ':taskId',
            name: 'Task',
            component: Task,
          },
        ],
      },
      {
        path: '/applications',
        component: Applications,
        children: [
          {
            path: ':applicationId',
            name: 'Application',
            component: Application,
          },
        ],
      },
      {
        path: '/subscriptions',
        component: Subscriptions,
        children: [
          {
            path: '',
            name: 'SubscriptionList',
            component: SubscriptionsList,
          },
          {
            path: ':subscriptionId',
            name: 'Subscription',
            component: Subscription,
          },
        ],
      },
      {
        path: '/leads',
        component: Leads,
        children: [
          {
            path: '',
            name: 'LeadsList',
            component: LeadsList,
          },
          {
            path: ':leadId',
            name: 'Lead',
            component: Lead,
          },
        ],
      },
      {
        path: '/agents',
        component: Agents,
        children: [
          {
            path: '',
            name: 'AgentsList',
            component: AgentsList,
          },
          {
            path: ':agentId',
            name: 'Agent',
            component: Agent,
          },
        ],
      },
      {
        path: '/categories',
        component: Categories,
        children: [
          {
            path: '',
            name: 'CategoriesList',
            component: CategoriesList,
          },
          {
            path: 'create',
            name: 'CategoryCreate',
            component: CategoryCreate,
          },
          {
            path: ':categoryId',
            name: 'CategoryEdit',
            component: CategoryEdit,
          },
        ],
      },
      {
        path: '/skills',
        component: Skills,
        children: [
          {
            path: '',
            name: 'SkillsList',
            component: SkillList,
          },
          {
            path: 'create',
            name: 'SkillCreate',
            component: SkillCreate,
          },
          {
            path: ':skillId',
            name: 'SkillEdit',
            component: SkillEdit,
          },
        ],
      },
      {
        path: '/payments',
        component: Payments,
        children: [
          {
            path: '',
            name: 'PaymentList',
            component: PaymentsList,
          },
          {
            path: ':paymentId',
            name: 'Payment',
            component: Payment,
          },
        ],
      },
      {
        path: '/freelancers',
        component: Freelancers,
        children: [
          {
            path: '',
            name: 'FreelancersSearch',
            component: FreelancersSearch,
          },
          {
            path: ':freelancerId',
            name: 'Freelancer',
            component: Freelancer,
          },
        ],
      },
      {
        path: '/clients',
        component: Clients,
        children: [
          {
            path: '',
            name: 'ClientsSearch',
            component: ClientsSearch,
          },
          {
            path: 'create',
            name: 'CreateClient',
            component: CreateClient,
          },
          {
            path: ':clientId',
            name: 'Client',
            component: Client,
          },
        ],
      },
      {
        path: '/users',
        component: Users,
        children: [
          {
            path: '',
            name: 'UsersSearch',
            component: UsersSearch,
          },
          {
            path: ':userId',
            name: 'User',
            component: User,
          },
        ],
      },
      {
        path: '/blog',
        component: Blog,
        children: [
          {
            path: '',
            name: 'BlogPosts',
            component: BlogPosts,
          },
          {
            path: 'create',
            name: 'BlogCreate',
            component: BlogCreate,
          },
          {
            path: ':postId',
            name: 'BlogEdit',
            component: BlogEdit,
          },
        ],
      },
      {
        path: '/testimonials',
        component: Testimonials,
        children: [
          {
            path: '',
            name: 'TestimonialList',
            component: TestimonialList,
          },
          {
            path: 'create',
            name: 'TestimonialsCreate',
            component: TestimonialsCreate,
          },
          {
            path: ':testimonialId',
            name: 'TestimonialsEdit',
            component: TestimonialsEdit,
          },
        ],
      },
      {
        path: '/pages',
        component: Pages,
        children: [
          {
            path: '',
            name: 'PagesList',
            component: PagesList,
          },
          {
            path: 'create',
            name: 'PageCreate',
            component: PageCreate,
          },
          {
            path: ':pageId',
            name: 'PageEdit',
            component: PageEdit,
          },
        ],
      },
      {
        path: '/newsletter',
        component: Newsletter,
        children: [
          {
            path: '',
            name: 'NewslettersList',
            component: NewslettersList,
          },
          {
            path: 'create',
            name: 'NewslettersCreate',
            component: NewsletterCreate,
          },
          {
            path: ':newsletterId/send',
            name: 'NewsletterSend',
            component: NewsletterSend,
          },
          {
            path: ':newsletterId/sent',
            name: 'NewsletterSent',
            component: NewsletterSent,
          },
          {
            path: ':newsletterId/sent/:sentId/statistics',
            name: 'NewsletterSentStats',
            component: NewsletterStats,
          },
          {
            path: ':newsletterId',
            name: 'NewsletterEdit',
            component: NewsletterBuilder,
          },
        ],
      },
      {
        path: '/templates',
        component: Templates,
        children: [
          {
            path: '',
            name: 'TemplatesList',
            component: TemplatesList,
          },
          {
            path: 'create',
            name: 'TemplatesCreate',
            component: TemplateCreate,
          },
          {
            path: ':templateId',
            name: 'TemplateEdit',
            component: TemplateBuilder,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
