<template>
  <div class="row">
    <div class="col-12">
      <b-button variant="primary" class="mb-3" to="/categories/create">
        Create new category
      </b-button>
      <b-card header="Categories" header-bg-variant="primary">
        <b-table striped :items="itemsProvider" :fields="fields" :per-page="perPage"
                 :current-page="currentPage" id="categories-table">
          <template v-slot:cell(id)="data">
            <router-link :to="`/categories/${data.item.id}`">{{ data.item.id }}</router-link>
          </template>
          <template v-slot:cell(name)="data">
            <category-list-title :category="data.item"/>
          </template>
          <template v-slot:cell(skills)="data">
            <router-link :to="`/skills?categoryId=${data.item.id}`">Skills</router-link>
          </template>
        </b-table>

        <template v-slot:footer class="clearfix">
          <b-pagination
            class="m-0 float-right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="categories-table"/>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import CategoryListTitle from '../../components/categories/CategoryListTitle.vue';

export default {
  name: 'CategoriesList',
  components: { CategoryListTitle },
  data() {
    return {
      fields: ['id', 'name', 'skills'],
      perPage: 20,
      currentPage: null,
      rows: 0,
    };
  },
  methods: {
    async itemsProvider(ctx) {
      const { data } = await ApiService.get(`/categories?page=${ctx.currentPage}`);
      this.rows = data.meta.total;
      return data.data;
    },
  },
};
</script>

<style scoped>

</style>
