<template>
  <div>
    <b-button variant="danger" v-if="user.active" @click.prevent="toggleUser">
      Deactivate
    </b-button>
    <b-button variant="success" v-if="!user.active" @click.prevent="toggleUser">
      Activate
    </b-button>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ToggleUserButtons',
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    async toggleUser() {
      try {
        await ApiService.post(`/users/${this.user.id}/toggle`);
        this.$emit('toggled');
        this.$bvToast.toast('User activity status changed', {
          title: 'User saved',
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast('User activity status NOT changed', {
          title: 'User NOT saved',
          variant: 'danger',
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
