<template>
  <div>
    <b-button-group class="mb-3">
      <b-button to="/testimonials/create">
        Create new testimonial
      </b-button>
    </b-button-group>
    <b-form @submit.prevent="getItems">
      <b-input-group class="mb-4">
        <b-form-select v-model="languageId" :options="languages" @change="getItems"/>
        <b-input-group-append>
          <b-button variant="success" type="submit">Search</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <div class="row">
      <div class="col-12">
        <b-card header="Testimonials" header-bg-variant="primary">
          <b-table striped :items="items" :fields="fields" id="my-table">
            <template v-slot:cell(name)="data">
              <router-link :to="`/testimonials/${data.item.id}`">{{ data.item.name }}</router-link>
            </template>
            <template v-slot:cell(title)="data">
              <router-link :to="`/testimonials/${data.item.id}`">{{ data.item.title }}</router-link>
            </template>
            <template v-slot:cell(language)="data">
              {{ data.item.language.name }}
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'TestimonialList',
  data() {
    return {
      fields: ['name', 'title', 'language'],
      items: [],
      languageId: 'en',
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      const headers = {};
      if (this.languageId) {
        headers['x-language'] = this.languageId;
      }
      const res = await ApiService.get('/testimonials', { headers });
      this.items = res.data.data;
    },
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        value: l.code,
        text: l.name,
      }));
    },
  },
};
</script>

<style scoped>

</style>
