<template>
  <font-awesome-icon :icon="icon"/>
</template>

<script>
export default {
  name: 'StarToggle',
  props: {
    starred: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.starred ? 'fa-solid fa-star' : 'fa-regular fa-star';
    },
  },
};
</script>

<style scoped>

</style>
