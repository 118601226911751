<template>
  <b-form @submit.prevent="search">
    <b-input-group class="mb-4">
      <b-form-input
        v-model="form.q"
        type="text"
        placeholder="Enter search term"/>
      <b-input-group-append>
        <b-button variant="danger" @click.prevent="clear">Clear</b-button>
      </b-input-group-append>
      <b-input-group-append>
        <b-button variant="success" type="submit">Search</b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'SearchForm',
  data() {
    return {
      form: {
        q: '',
      },
    };
  },
  methods: {
    clear() {
      this.form.q = '';
      this.search();
    },
    search() {
      this.$emit('search', this.form);
    },
  },
};
</script>
