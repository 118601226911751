const initialState = {
  searchParams: {
    q: '',
    published: '',
    confirmed: '',
  },
};

const actions = {};

const mutations = {
  setSearchParams(state, params) {
    state.searchParams = params;
  },
};

const getters = {};

const store = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};

export default store;
