<template>
  <div>
    <div class="row">
      <div class="col-6">
        <b-card header-bg-variant="primary" :header="user.email">
          <dl class="row mb-3">
            <dt class="col-sm-3">Id</dt>
            <dd class="col-sm-9">{{ user.id }}</dd>

            <dt class="col-sm-3">Email</dt>
            <dd class="col-sm-9">{{ user.email }}</dd>

            <dt class="col-sm-3">Created at</dt>
            <dd class="col-sm-9">{{ user.createdAt }}</dd>

            <dt class="col-sm-3">Updated at</dt>
            <dd class="col-sm-9">{{ user.updatedAt }}</dd>

            <dt class="col-sm-3">Last login</dt>
            <dd class="col-sm-9">{{ user.lastLogin }}</dd>
          </dl>

          <b-button variant="success" type="link" :href="loginAsUrl">Login as user</b-button>
        </b-card>

        <b-card header-bg-variant="danger" header="Roles">
          <dl class="row mb-0">
            <dt class="col-sm-3">Roles</dt>
            <dd class="col-sm-9">{{ roles }}</dd>
          </dl>

          <b-button variant="danger" v-if="isAdmin" @click.prevent="toggleAdmin">
            Remove admin permissions
          </b-button>
          <b-button variant="success" v-if="!isAdmin" @click.prevent="toggleAdmin">
            Set as administrator
          </b-button>
        </b-card>
      </div>
      <div class="col-6">
        <b-card header-bg-variant="info" header="Email confirmation">
          <dl class="row">
            <dt class="col-sm-3">Email confirmed</dt>
            <dd class="col-sm-9">{{ user.emailConfirmed }}</dd>

            <dt class="col-sm-3">Confirmation hash</dt>
            <dd class="col-sm-9">{{ user.confirmationHash }}</dd>
          </dl>

          <div class="d-flex align-items-center">
            <b-button variant="warning" class="mr-3" v-if="!user.emailConfirmed"
                      @click.prevent="sendConfirmation">
              Send confirmation email
            </b-button>
            <b-form-checkbox
              id="sendPassword"
              v-model="sendPassword"
              name="sendPassword"
              :value="true"
              :unchecked-value="false"
            >
              Send new password
            </b-form-checkbox>
          </div>
        </b-card>

        <b-card header-bg-variant="warning" header="Active status">
          <dl class="row mb-0">
            <dt class="col-sm-3">Active</dt>
            <dd class="col-sm-9">{{ user.active }}</dd>
          </dl>

          <toggle-user-buttons :user="user" @toggled="toggleUser"/>
        </b-card>

        <user-language :user="user"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <user-emails v-if="user.email" :user="user"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import UserEmails from '../../components/user/UserEmails.vue';
import ToggleUserButtons from '../../components/user/ToggleUserButtons.vue';
import UserLanguage from '../../components/user/UserLanguage.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'User',
  components: {
    UserLanguage,
    ToggleUserButtons,
    UserEmails,
  },
  // components: { ToggleUserButtons },
  data() {
    return {
      user: {},
      sendPassword: false,
    };
  },
  created() {
    const { userId } = this.$route.params;

    ApiService.get(`/users/${userId}`)
      .then((res) => {
        this.user = res.data.data;
      });
  },
  methods: {
    async toggleAdmin() {
      const { userId } = this.$route.params;

      await ApiService.put(`/users/${userId}/toggle-admin`);

      if (this.isAdmin) {
        this.user.roles = this.user.roles.filter((r) => r.name !== 'administrator');
      } else {
        this.user.roles.push({ name: 'administrator' });
      }
    },

    async sendConfirmation() {
      const res = await ApiService.post(`/users/${this.user.id}/confirmation`, { sendPassword: this.sendPassword });
      this.user.confirmationHash = res.data.data;
    },

    toggleUser() {
      this.user = {
        ...this.user,
        active: !this.user.active,
      };
    },
  },
  computed: {
    roles() {
      return this.user.roles ? this.user.roles.map((r) => r.name)
        .join(', ') : '';
    },

    isAdmin() {
      return this.user.roles ? this.user.roles.findIndex((r) => r.name === 'administrator') > -1 : false;
    },

    loginAsUrl() {
      return `${process.env.VUE_APP_LANDING_URL}login-as/${this.user.id}`;
    },
  },
};
</script>

<style scoped>

</style>
