<template>
  <div class="info-box">
    <span :class="`info-box-icon bg-${type}`">
      <font-awesome-icon :icon="icon"/>
    </span>

    <div class="info-box-content">
      <span class="info-box-text">{{ label }}</span>
      <span class="info-box-number">{{ number }}</span>
    </div>
    <!-- /.info-box-content -->
  </div>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'InfoBox',
  props: {
    type: {
      type: String,
      default: 'green',
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    number: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
