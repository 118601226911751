import TokenService from '../services/TokenService';
import ApiService from '../services/ApiService';
import router from '../router';

const initialState = {
  user: null,
  token: null,
};

const actions = {
  /**
   * Init auth data (token and user) from local storage and API
   * @param commit
   * @param state
   * @param dispatch
   */
  async init({ commit, state, dispatch }) {
    // check if there is token already in store
    if (!state.token) {
      const token = TokenService.getToken();

      // If token exists set header
      if (token) {
        ApiService.setHeader();

        commit('setToken', token);
      } else {
        throw new Error('Token not found');
      }
    }

    // check if there is stored user already
    if (!state.user && state.token) {
      try {
        const user = await ApiService.get('/users/me');
        const userData = user.data.data;

        if (!userData.roles.length || !userData.roles.find((r) => r.name === 'administrator')) {
          dispatch('logout');
        } else {
          commit('setUser', userData);
        }
      } catch (err) {
        TokenService.removeToken();
        ApiService.removeHeader();
        commit('setToken', null);
        commit('setUser', null);

        throw new Error('User not authenticated');
      }
    }

    return true;
  },

  /**
   * Log in user
   * @param commit
   * @param dispatch
   * @param {Object} credentials
   * @param {String} credentials.email
   * @param {String} credentials.password
   * @return {Promise<void>}
   */
  async login({ commit, dispatch }, credentials) {
    const login = await ApiService.post('/auth/login', credentials);
    commit('setToken', login.data.data.token);

    TokenService.saveToken(login.data.data.token);
    ApiService.setHeader();

    const user = await ApiService.get('/users/me');
    const userData = user.data.data;

    if (!userData.roles.length || !userData.roles.find((r) => r.name === 'administrator')) {
      dispatch('logout');
      throw new Error('User is not administrator');
    } else {
      commit('setUser', userData);
    }
  },

  /**
   * Log out user
   * @param commit
   */
  logout({ commit }) {
    router.replace('/login');

    commit('setToken', null);

    TokenService.removeToken();
    ApiService.removeHeader();

    commit('setUser', null);
  },
};

const mutations = {
  /**
   * Set token
   * @param state
   * @param token
   */
  setToken(state, token) {
    state.token = token;
  },

  /**
   * Set user data
   * @param state
   * @param user
   */
  setUser(state, user) {
    state.user = user;
  },
};

const getters = {};

const store = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};

export default store;
