<template>
  <div class="row">
    <div class="col-12">
      <b-card header-bg-variant="primary" header="Create Blog Post">
        <b-form @submit.prevent="save">
          <image-uploader label="Intro image" title="Intro image" :value="post.image"
                          :croppable="false" @input="imageAdded" @remove="imageRemoved"/>

          <b-form-group id="title" label="Title">
            <b-form-input v-model="post.title"/>
          </b-form-group>

          <b-form-group id="description" label="Content">
            <wysiwyg-editor v-model="post.text"/>
          </b-form-group>

          <b-form-group id="type" label="Language">
            <b-form-select v-model="post.languageId" :options="languages"/>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import WysiwygEditor from '../../components/form/WysiwygEditor.vue';
import ImageUploader from '../../components/form/ImageUploader.vue';
import ApiService from '../../services/ApiService';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'BlogCreate',
  components: {
    ImageUploader,
    WysiwygEditor,
  },
  data() {
    return {
      post: {
        title: '',
        text: '',
        languageId: null,
        imageId: null,
        image: null,
      },
    };
  },
  methods: {
    async save() {
      try {
        await ApiService.post('/posts', this.post);
        this.$bvToast.toast('Post successfully saved', {
          title: 'Post saved',
          variant: 'success',
        });
        await this.$router.replace('/blog');
      } catch (err) {
        this.$bvToast.toast('Post NOT saved', {
          title: 'Error',
          variant: 'danger',
        });
      }
    },

    imageAdded(data) {
      this.post.image = data;
      this.post.imageId = data.id;
    },

    imageRemoved() {
      this.post.image = null;
      this.post.imageId = null;
    },
  },
  computed: {
    languages() {
      return this.$store.getters['util/getEnabledLanguages'].map((l) => ({
        value: l.id,
        text: l.name,
      }));
    },
  },
};
</script>
